import type { ReactNode } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { getRemoteConfigValueByKey } from 'api/remote-config';

interface FeatureFlagContextProps {
  featureFlags: { [key: string]: boolean };
  setFeatureFlag: (flag: string, value: boolean) => void;
}

const FeatureFlagContext = createContext<FeatureFlagContextProps | undefined>(
  undefined,
);

export const FeatureFlagProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [featureFlags, setFeatureFlags] = useState<{ [key: string]: boolean }>(
    {},
  );
  useEffect(() => {
    (async () => {
      const value = await getRemoteConfigValueByKey('studio_flag');
      console.log(JSON.stringify(value.asString()));
    })();
  }, []);

  const setFeatureFlag = (flag: string, value: boolean) => {
    setFeatureFlags((prevFlags) => ({
      ...prevFlags,
      [flag]: value,
    }));
  };

  return (
    <FeatureFlagContext.Provider value={{ featureFlags, setFeatureFlag }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = (): FeatureFlagContextProps => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagProvider',
    );
  }
  return context;
};
