import { useCallback, useEffect } from 'react';

import { captureException, captureMessage } from '@sentry/nextjs';
import { doc, getFirestore, setDoc, updateDoc } from 'firebase/firestore/lite';

import { getChannelDetailData } from 'api/channel';
import { getUserSubscriptionInfo } from 'api/firestore';

import useAuth from 'hooks/useAuth';
import useManagerAuth from 'hooks/useManagerAuth';

import {
  UN_AUTHENTICATE,
  USER_PROFILE_LOAD_SUCCESS,
  USER_PROFILE_UPDATE,
} from 'types/auth';

import getGaClientId from 'utils/getGaClientId';

const useFetchUser = ({ role }: { role: 'owner' | 'manager' }) => {
  const db = getFirestore();

  let authContext;

  const ownerAuthContext = useAuth();
  const managerAuthContext = useManagerAuth();

  if (role === 'owner') {
    authContext = ownerAuthContext;
  } else {
    authContext = managerAuthContext;
  }

  const token = ownerAuthContext.token;
  const {
    dispatch,
    initializeProfile,
    setChannel,
    updateSubscriptionState,
    updateUserDataLoading,
    user: { id },
  } = authContext;

  const setDefaultUserSubscriptionInfo = useCallback(
    async (uid: string) => {
      try {
        await setDoc(doc(db, 'studio_users', uid), {
          isSubscriber: false,
          uid,
        });
      } catch {
        dispatch({
          type: UN_AUTHENTICATE,
        });
      }
    },
    [db, dispatch],
  );

  const updateClientId = useCallback(async ({ uid }: { uid: string }) => {
    try {
      const clientIdForGA4 = getGaClientId();

      if (!clientIdForGA4) return;

      const db = getFirestore();
      await updateDoc(doc(db, 'studio_users', uid), {
        clientId: clientIdForGA4,
      });
    } catch (error) {
      captureMessage(`updateClientId 에러 : ${error}`);
    }
  }, []);

  useEffect(() => {
    if (id) updateClientId({ uid: id });
  }, [id, updateClientId]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (Boolean(id) === false || token === '') return;

        updateUserDataLoading(true);
        const data = await initializeProfile({
          uid: id,
          token,
          manageChannelUid: role === 'manager' ? id : undefined,
        });

        const nickname = data?.nickname;
        if (nickname === undefined) return;

        const [subscriptionInfoResult, channelInfoResult] =
          await Promise.allSettled([
            getUserSubscriptionInfo(id),
            getChannelDetailData(nickname),
          ]);

        if (subscriptionInfoResult.status === 'fulfilled') {
          if (subscriptionInfoResult.value) {
            updateSubscriptionState(subscriptionInfoResult.value);
            const isSubscribedUser =
              subscriptionInfoResult.value?.isSubscriber || false;
            dispatch({
              type: USER_PROFILE_UPDATE,
              payload: {
                isSubscribedUser,
              },
            });
          } else {
            await setDefaultUserSubscriptionInfo(id);
          }
        }

        if (channelInfoResult.status === 'fulfilled') {
          setChannel(channelInfoResult.value);
        }

        dispatch({
          type: USER_PROFILE_LOAD_SUCCESS,
          payload: {
            isSubscribedUser:
              subscriptionInfoResult.status === 'fulfilled' &&
              subscriptionInfoResult.value?.isSubscriber,
          },
        });
      } catch (e) {
        captureException(e);
        //TODO: 여기랑 token 기반 signIn이랑 둘 중 하나가 에러가 났을 때에 대한 처리가 필요
      } finally {
        updateUserDataLoading(false);
      }
    };

    getData();
  }, [
    dispatch,
    updateClientId,
    id,
    initializeProfile,
    role,
    setChannel,
    setDefaultUserSubscriptionInfo,
    token,
    updateSubscriptionState,
    updateUserDataLoading,
  ]);
};

export default useFetchUser;
