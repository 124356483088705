import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { getApp } from 'firebase/app';
import type { StorageReference } from 'firebase/storage';
import {
  deleteObject,
  getBytes,
  getDownloadURL,
  getStorage,
  list,
  listAll,
  ref,
  uploadBytes,
} from 'firebase/storage';

import type { MatchImageType } from 'types/matchData';
import type {
  SportType,
  TeamType,
  WidgetImgURL,
  WidgetThemeType,
} from 'types/scoreboardData';

import { bytesToMegabytesOrKilobytes } from 'utils/bytesToMegabytesOrKilobytes';
import { convertCommonType } from 'utils/getConvertedValue';

import { updateAwayTeamAvatar, updateHomeTeamAvatar } from './RDB/util';

const getScoreboardThemeImgs = async (type: SportType) => {
  const storage = getStorage();
  const commonType = convertCommonType(type);
  const themeImgsRef = ref(storage, `scoreboard/theme/${commonType}`);
  const themeUrlList: { theme: WidgetThemeType; url: string }[] = [];

  const themeImgRefs: StorageReference[] = await listAll(themeImgsRef)
    .then((res) => res.items)
    .catch((error) => {
      console.error('listAll error : ', error);
      return [];
    });

  await Promise.allSettled(
    themeImgRefs.map(async (item: StorageReference, idx: number) => {
      const { name } = item;
      const theme = name.split('.')[0] as WidgetThemeType;
      const url = await getDownloadURL(item);
      themeUrlList[idx] = { theme, url };
    }),
  ).catch((err) => console.error('Get theme img error', err));
  return themeUrlList;
};

const getLottieThemeImgs = async (type: SportType) => {
  const storage = getStorage();
  const commonType = convertCommonType(type);
  const themeImgsRef = ref(storage, `scoreboard/theme/${commonType}/lottie`);
  const themeUrlList: { theme: WidgetThemeType; url: string }[] = [];

  const themeImgRefs: StorageReference[] = await listAll(themeImgsRef)
    .then((res) => res.items)
    .catch((error) => {
      console.error('listAll error : ', error);
      return [];
    });

  await Promise.allSettled(
    themeImgRefs.map(async (item: StorageReference, idx: number) => {
      const { name } = item;
      const theme = name.split('.')[0] as WidgetThemeType;
      const url = await getDownloadURL(item);
      themeUrlList[idx] = { theme, url };
    }),
  ).catch((err) => console.error('Get theme img error', err));
  return themeUrlList;
};

const getOverlayThemeImgs = async () => {
  const storage = getStorage();
  const themeImgsRef = ref(storage, 'scoreboard/overlayTheme');
  const themeUrlList: { theme: WidgetThemeType; url: string }[] = [];

  const themeImgRefs: StorageReference[] = await listAll(themeImgsRef)
    .then((res) => res.items)
    .catch((error) => {
      console.error('listAll error : ', error);
      return [];
    });

  await Promise.allSettled(
    themeImgRefs.map(async (item: StorageReference, idx: number) => {
      const { name } = item;
      const theme = name.split('.')[0] as WidgetThemeType;
      const url = await getDownloadURL(item);
      themeUrlList[idx] = { theme, url };
    }),
  ).catch((err) => console.error('Get theme img error', err));
  return themeUrlList;
};

export const getWidgetImgs = async (
  type: SportType,
): Promise<WidgetImgURL[]> => {
  const sportThemeImgs = await getScoreboardThemeImgs(type);
  const lottieThemeImgs = await getLottieThemeImgs(type);
  const overlayThemeImgs = await getOverlayThemeImgs();
  return [...sportThemeImgs, ...lottieThemeImgs, ...overlayThemeImgs];
};

export const getLeaderboardFileUrl = async (
  fileName: string,
  uid: string,
  leaderboardId: number,
) => {
  const storage = getStorage();
  const leaderboardFileRef = ref(
    storage,
    `leaderboard/${uid}/${leaderboardId}/file/${fileName}`,
  );

  return await getDownloadURL(leaderboardFileRef);
};

export const getLeaderboardFileNames = async (
  uid: string,
  leaderboardId: number,
) => {
  const storage = getStorage();
  const leaderboardFileRef = ref(
    storage,
    `leaderboard/${uid}/${leaderboardId}/file`,
  );

  const fileInfos: {
    fileName: string;
    fileSize: string;
    file?: File;
    downloadCount: number;
  }[] = [];

  const leaderboardFileRefs: StorageReference[] = await listAll(
    leaderboardFileRef,
  )
    .then((res) => res.items)
    .catch((error) => {
      console.error('get leaderboard file name listAll fnc error : ', error);
      return [];
    });

  const getFileInfos = async () => {
    for (let i = 0; i < leaderboardFileRefs.length; i++) {
      const size = await getBytes(leaderboardFileRefs[i]);
      const fileSize = bytesToMegabytesOrKilobytes(size.byteLength);
      fileInfos.push({
        fileName: leaderboardFileRefs[i].name,
        fileSize,
        downloadCount: 0,
      });
    }
  };

  await getFileInfos();

  return fileInfos;
};

const actionImgCompress = async (
  key:
    | 'avatar'
    | 'channelBanner'
    | 'eventGallery'
    | 'match'
    | 'leaderboard'
    | 'member'
    | 'videoThumbnail'
    | 'channelImageBanner'
    | 'postImage'
    | 'galleryImage',
  fileSrc: File,
) => {
  const options = {
    avatar: {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 300,
      useWebWorker: true,
    },
    channelBanner: {
      maxSizeMB: 1,
      maxWidthOrHeight: 2400,
      useWebWorker: true,
    },
    eventGallery: {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 4000,
      useWebWorker: true,
    },
    match: {
      maxSizeMB: 5,
      maxWidthOrHeight: 2400,
      useWebWorker: true,
    },
    leaderboard: {
      maxSizeMB: 5,
      maxWidthOrHeight: 2400,
      useWebWorker: true,
    },
    member: {
      maxSizeMB: 1,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    },
    videoThumbnail: {
      maxSizeMB: 5,
      maxWidthOrHeight: 2400,
      useWebWorker: true,
    },
    channelImageBanner: {
      maxSizeMB: 5,
      maxWidthOrHeight: 1200,
      useWebWorker: true,
    },
    postImage: {
      maxSizeMB: 5,
      maxWidthOrHeight: 2400,
      useWebWorker: true,
    },
    galleryImage: {
      maxSizeMB: 5,
      maxWidthOrHeight: 2400,
      useWebWorker: true,
    },
  };

  try {
    const compressedFile = await imageCompression(fileSrc, options[key]);
    return compressedFile;
  } catch (error) {
    console.error('Error while compressing image : ', error);
    return fileSrc;
  }
};

export const updateAvatarImg = async (
  file: File,
  matchId: string,
  teamType: TeamType,
  updateFrom: 'store' | 'rdb',
) => {
  let isError = false;
  const storage = getStorage();
  const userAvatarRef = ref(
    storage,
    `matches/${matchId}/${teamType}TeamAvatar`,
  );
  const compressedBlob = await actionImgCompress('avatar', file);
  const convertedFile = new File([compressedBlob], file.name, {
    type: 'image/webp',
  });
  await uploadBytes(userAvatarRef, convertedFile).catch(() => (isError = true));

  if (isError) return null;
  return await getDownloadURL(userAvatarRef)
    .then((url) => {
      if (updateFrom === 'rdb') {
        teamType === 'home'
          ? updateHomeTeamAvatar(matchId, url)
          : updateAwayTeamAvatar(matchId, url);
      }
      return url;
    })
    .catch(() => null);
};

export const uploadProfileImgToStorage = async (file: File, uid: string) => {
  const storage = getStorage();
  const userProfileRef = ref(storage, `image_profile/${uid}`);
  const compressedBlob = await actionImgCompress('avatar', file);
  const convertedFile = new File([compressedBlob], file.name, {
    type: 'image/webp',
  });

  await uploadBytes(userProfileRef, convertedFile).catch((e) => {
    console.error('Error while uploading file to storage : ', e);
    return null;
  });

  return await getDownloadURL(userProfileRef).catch((e) =>
    console.error('File link error : ', e),
  );
};

export const deleteProfileImg = async (uid: string) => {
  const storage = getStorage();
  const userProfileRef = ref(storage, `image_profile/${uid}`);
  try {
    await deleteObject(userProfileRef);
  } catch (e) {
    console.error(e);
  }
};

export const uploadChannelBannerImgToStorage = async (
  file: File,
  uid: string,
) => {
  const storage = getStorage();
  const channelBannerRef = ref(storage, `image_channelBanner/${uid}`);
  const compressedBlob = await actionImgCompress('channelBanner', file);
  const convertedFile = new File([compressedBlob], file.name, {
    type: 'image/webp',
  });

  await uploadBytes(channelBannerRef, convertedFile).catch((e) => {
    console.error('Error while uploading file to storage : ', e);
    return null;
  });

  return await getDownloadURL(channelBannerRef).catch((e) =>
    console.error('File link error : ', e),
  );
};

export const deleteChannelBanner = async (uid: string) => {
  const storage = getStorage();
  const channelBannerRef = ref(storage, `image_channelBanner/${uid}`);
  try {
    await deleteObject(channelBannerRef);
  } catch (e) {
    console.error(e);
  }
};

/**
 * @description
 * 채널페이지 홈탭에 이미지배너를 업로드합니다. (채널배너x)
 */
export const uploadChannelImageBannerToStorage = async ({
  file,
  channelId,
  imageBannerId,
}: {
  file: File;
  channelId: number;
  imageBannerId: number;
}) => {
  const storage = getStorage();
  const imageBannerRef = ref(
    storage,
    `image_channelImageBanner/${channelId}/${imageBannerId}`,
  );

  try {
    const compressedBlob = await actionImgCompress('channelImageBanner', file);
    const convertedFile = new File([compressedBlob], file.name, {
      type: 'image/webp',
    });
    await uploadBytes(imageBannerRef, convertedFile);

    return await getDownloadURL(imageBannerRef);
  } catch (e) {
    console.error('Error while uploading file to storage : ', e);
  }
};

export const deleteChannelImageBanner = async ({
  channelId,
  imageBannerId,
}: {
  channelId: number;
  imageBannerId: number;
}) => {
  const storage = getStorage();
  const imageBannerRef = ref(
    storage,
    `image_channelImageBanner/${channelId}/${imageBannerId}`,
  );

  try {
    await deleteObject(imageBannerRef);
  } catch (e) {
    console.error(e);
  }
};

export const uploadPostImageListToStorage = async ({
  imageIdList,
  fileList,
  postId,
}: {
  imageIdList: number[];
  fileList: File[];
  postId: number;
}) => {
  const storage = getStorage();

  const imageLinkList = fileList.map(async (file, idx) => {
    const postImageRef = ref(
      storage,
      `image_post/${postId}/${imageIdList[idx]}`,
    );

    try {
      const compressedBlob = await actionImgCompress('postImage', file);
      const convertedFile = new File(
        [compressedBlob],
        imageIdList[idx].toString(),
        { type: 'image/webp' },
      );

      await uploadBytes(postImageRef, convertedFile);

      return await getDownloadURL(postImageRef);
    } catch (e) {
      console.error('Error while uploading file to storage : ', e);
    }
  });

  return Promise.all(imageLinkList).then((results) => {
    const validResults = results.filter((result) => result !== undefined);
    return validResults;
  });
};

export const deletePostImageToStorage = async ({
  postId,
  imageIdList,
}: {
  postId: number;
  imageIdList: number[];
}) => {
  const storage = getStorage();

  try {
    await Promise.all(
      imageIdList.map(async (file, idx) => {
        const postImageRef = ref(
          storage,
          `image_post/${postId}/${imageIdList[idx]}`,
        );

        return await deleteObject(postImageRef);
      }),
    );
  } catch (e) {
    console.error(e);
  }
};

export const updateTeamLogoToStorage = async (file: File, teamId: number) => {
  const storage = getStorage();
  const teamLogoRef = ref(storage, `teams/${teamId}/teamLogo`);

  const compressedBlob = await actionImgCompress('avatar', file);
  const convertedFile = new File([compressedBlob], file.name, {
    type: 'image/webp',
  });

  await uploadBytes(teamLogoRef, convertedFile);

  return await getDownloadURL(teamLogoRef);
};

export const updateMatchImg = async (
  matchUid: string,
  files: {
    fileName: string;
    url: string;
    tempSubFile?: File;
  }[],
) => {
  const uniqueFiles = files.reduce((unique: typeof files, current) => {
    const foundFile = unique.find((item) => item.fileName === current.fileName);
    if (!foundFile) {
      return unique.concat([current]);
    } else {
      return unique;
    }
  }, []);
  const storage = getStorage();
  const convertedMatchImg: MatchImageType[] = [];
  await Promise.allSettled(
    uniqueFiles.map(async (matchImageData: MatchImageType) => {
      if (matchImageData.tempSubFile) {
        const address = `matches/${matchUid}/matchImages/${matchImageData.fileName}`;
        const matchImageRef = ref(storage, address);
        const compressedBlob = await actionImgCompress(
          'match',
          matchImageData.tempSubFile,
        );

        const convertedFile = new File(
          [compressedBlob],
          matchImageData.fileName,
          {
            type: 'image/webp',
          },
        );
        await uploadBytes(matchImageRef, convertedFile);

        const firebaseUrl = await getDownloadURL(matchImageRef);
        convertedMatchImg.push({
          fileName: matchImageData.fileName,
          url: firebaseUrl,
        });
      } else {
        convertedMatchImg.push(matchImageData);
      }
    }),
  ).catch((error) => {
    return Promise.reject(new Error(`updateMatchImg error : ${error}`));
  });
  return convertedMatchImg;
};

export const updateLeaderboardImg = async (
  file: File,
  uid: string,
  leaderboardId: number,
) => {
  const storage = getStorage();
  try {
    const leaderboardImageRef = ref(
      storage,
      `leaderboard/${uid}/${leaderboardId}/image/${file.name}`,
    );

    const compressedBlob = await actionImgCompress('leaderboard', file);
    const convertedFile = new File([compressedBlob], file.name, {
      type: 'image/webp',
    });
    await uploadBytes(leaderboardImageRef, convertedFile);
    const imgUrl = await getDownloadURL(leaderboardImageRef);

    return { url: imgUrl, name: convertedFile.name, size: convertedFile.size };
  } catch (e) {
    throw new Error(`updateLeaderboardImg error : ${e}`);
  }
};

export const updateLeaderboardTeamImg = async (
  teamImgInfos: {
    file: File;
    teamName: string;
    teamId: number;
  }[],
  uid: string,
  leaderboardId: number,
) => {
  const storage = getStorage();
  try {
    const convertedTeamImgInfos: { logoUrl: string; id: number }[] = [];

    for (const teamImgInfo of teamImgInfos) {
      if (teamImgInfo.file === undefined || !teamImgInfo.teamId) continue;
      const leaderboardTeamImageRef = ref(
        storage,
        `leaderboard/${uid}/${leaderboardId}/teamImage/${teamImgInfo.teamId}`,
      );

      const compressedBlob = await actionImgCompress(
        'avatar',
        teamImgInfo.file,
      );
      const convertedFile = new File(
        [compressedBlob],
        `${teamImgInfo.teamId}`,
        {
          type: 'image/webp',
        },
      );
      await uploadBytes(leaderboardTeamImageRef, convertedFile);
      const logoUrl = await getDownloadURL(leaderboardTeamImageRef);
      convertedTeamImgInfos.push({ logoUrl, id: teamImgInfo.teamId });
    }

    return convertedTeamImgInfos;
  } catch (e) {
    throw new Error(`updateLeaderboardFiles error : ${e}`);
  }
};

export const updateLeaderboardFiles = async (
  fileInfos: { fileName: string; file?: File }[],
  uid: string,
  leaderboardId: number,
) => {
  const storage = getStorage();
  try {
    fileInfos.forEach(async (fileInfo) => {
      if (fileInfo.file === undefined) return;
      const leaderboardFileRef = ref(
        storage,
        `leaderboard/${uid}/${leaderboardId}/file/${fileInfo.fileName}`,
      );
      await uploadBytes(leaderboardFileRef, fileInfo.file);
    });
  } catch (e) {
    throw new Error(`updateLeaderboardFiles error : ${e}`);
  }
};

export const updateEventGalleryStorageImgs = async (
  uid: string,
  leaderboardId: number,
  files: File[],
) => {
  const storage = getStorage();
  const convertedEventImg: {
    fileName: string;
    url: string;
  }[] = [];

  await Promise.allSettled(
    files.map(async (file: File) => {
      const address = `leaderboard/${uid}/${leaderboardId}/galley/${file.name}`;
      const eventGalleyRef = ref(storage, address);
      const compressedBlob = await actionImgCompress('eventGallery', file);
      const convertedFile = new File([compressedBlob], file.name, {
        type: 'image/webp',
      });
      await uploadBytes(eventGalleyRef, convertedFile);

      const firebaseUrl = await getDownloadURL(eventGalleyRef);

      convertedEventImg.push({
        fileName: file.name,
        url: firebaseUrl,
      });
    }),
  ).catch((error) => {
    return Promise.reject(new Error(`updateMatchImg error : ${error}`));
  });
  return convertedEventImg;
};

export const updateTeamMemberProfileImg = async ({
  file,
  teamId,
  teamMemberId,
}: {
  file: File;
  teamId: number;
  teamMemberId: number;
}) => {
  const storage = getStorage();
  const teamMemberRef = ref(storage, `teams/${teamId}/member/${teamMemberId}`);

  const compressedBlob = await actionImgCompress('member', file);
  const convertedFile = new File([compressedBlob], file.name, {
    type: 'image/webp',
  });

  await uploadBytes(teamMemberRef, convertedFile);

  return await getDownloadURL(teamMemberRef);
};

export const deleteEventGalleryStorageImg = async (
  uid: string,
  leaderboardId: number,
  targetFileName: string,
) => {
  const storage = getStorage();
  const leaderboardGalleryImageRef = ref(
    storage,
    `leaderboard/${uid}/${leaderboardId}/galley/${targetFileName}`,
  );

  try {
    await deleteObject(leaderboardGalleryImageRef);
  } catch (e) {
    console.error(e);
  }
};

export const deleteMatchImg = async (
  matchId: string,
  files: {
    fileName: string;
    url: string;
  }[],
) => {
  const storage = getStorage();
  await Promise.allSettled(
    files.map((file) => {
      const deleteRef = ref(
        storage,
        `matches/${matchId}/matchImages/${file.fileName}`,
      );
      deleteObject(deleteRef);
    }),
  ).catch((error) => {
    return Promise.reject(new Error(`deleteMatchImg error : ${error}`));
  });
};

export const deleteLeaderboardTeamImg = async (
  uid: string,
  leaderboardId: number,
  teamId: number,
) => {
  const storage = getStorage();
  const leaderboardTeamImageRef = ref(
    storage,
    `leaderboard/${uid}/${leaderboardId}/teamImage/${teamId}`,
  );

  try {
    await deleteObject(leaderboardTeamImageRef);
  } catch (e) {
    console.error(e);
  }
};

export const deleteLeaderboardAllTeamImg = async (
  uid: string,
  leaderboardId: number,
) => {
  const storage = getStorage();
  const leaderboardTeamImageRef = ref(
    storage,
    `leaderboard/${uid}/${leaderboardId}/teamImage`,
  );

  const leaderboardImageResult = await list(leaderboardTeamImageRef);

  if (leaderboardImageResult.items.length !== 0) {
    for (const item of leaderboardImageResult.items) {
      await deleteObject(item);
    }
  }
};

export const deleteUnusedLeaderboardImg = async (
  uid: string,
  leaderboardId: number,
  usedImgs: (string | null)[],
) => {
  const storage = getStorage();
  const leaderboardImageRef = ref(
    storage,
    `leaderboard/${uid}/${leaderboardId}/image`,
  );
  const leaderboardImageResult = await list(leaderboardImageRef);
  if (leaderboardImageResult.items.length !== 0) {
    for (const item of leaderboardImageResult.items) {
      if (!usedImgs.includes(item.name)) await deleteObject(item);
    }
  }
};

export const deleteLeaderboardFiles = async (
  fileNames: string[],
  uid: string,
  leaderboardId: number,
) => {
  const storage = getStorage();
  const leaderboardFileRef = ref(
    storage,
    `leaderboard/${uid}/${leaderboardId}/file`,
  );
  const leaderboardResult = await list(leaderboardFileRef);
  if (leaderboardResult.items.length !== 0) {
    for (const item of leaderboardResult.items) {
      if (fileNames.includes(item.name)) await deleteObject(item);
    }
  }
};

export const deleteAvatarImg = async (matchId: string, teamType?: TeamType) => {
  const storage = getStorage();
  const matchAvatarRef = ref(storage, `matches/${matchId}`);
  const matchImagesRef = ref(storage, `matches/${matchId}/matchImages`);
  const matchAvatarResult = await list(matchAvatarRef);
  const matchImagesResult = await list(matchImagesRef);

  if (matchAvatarResult.items.length !== 0) {
    for (const item of matchAvatarResult.items) {
      if (!teamType) await deleteObject(item);
      else if (item.name.includes(teamType)) {
        const teamAvatarAddress = `matches/${matchId}/${teamType}TeamAvatar`;
        const userAvatarRef = ref(storage, teamAvatarAddress);
        await deleteObject(userAvatarRef);
      } else continue;
    }
  }

  if (!teamType && matchImagesResult.items.length !== 0) {
    for (const item of matchImagesResult.items) {
      await deleteObject(item);
    }
  }
};

export const deleteTeamLogo = async (uid: string, teamId: number) => {
  const storage = getStorage();
  let isNewPath = false;
  await deleteObject(ref(storage, `teamLogo/${uid}/${teamId}`)).catch((err) =>
    err.message.includes('does not exist')
      ? (isNewPath = true)
      : console.error(err),
  );

  // * 선수 프로필도 저장하게 되면서 경로(path)를 변경함에 따라 기존 경로에 이미지가 없는 경우 새로운 경로에 저장된 이미지 제거
  if (isNewPath) {
    await deleteObject(ref(storage, `teams/${teamId}/teamLogo`)).catch((err) =>
      console.error(err),
    );
  }
};

export const uploadCaptionSpeakerImg = async (
  file: File,
  uid: string,
  encryptedUid: string,
) => {
  const storage = getStorage();
  const captionSpeakerRef = ref(
    storage,
    `caption/${uid}/${encryptedUid}/${file.name}`,
  );

  await uploadBytes(captionSpeakerRef, file);
};

export const getCaptionSpeakerImgDownloadURL = async (
  name: string,
  uid: string,
  encryptedUid: string,
): Promise<string> => {
  const storage = getStorage();
  const captionSpeakerRef = ref(
    storage,
    `caption/${uid}/${encryptedUid}/${name}`,
  );
  const downloadUrl = await getDownloadURL(captionSpeakerRef);

  return downloadUrl;
};

export const copyTeamImgToNewMatch = async (
  originalMatchId: string,
  newMatchId: string,
  teamType: TeamType,
) => {
  const storage = getStorage();
  const sourceRef = ref(
    storage,
    `matches/${originalMatchId}/${teamType}TeamAvatar`,
  );
  const destRef = ref(storage, `matches/${newMatchId}/${teamType}TeamAvatar`);

  const { data } = await getDownloadURL(sourceRef)
    .then((url) => {
      return axios.get(url, {
        responseType: 'blob',
      });
    })
    .catch((err) => {
      throw new Error(err);
    });

  await uploadBytes(destRef, data).catch((err) => {
    throw new Error(err);
  });

  const downloadUrl = await getDownloadURL(destRef);

  return downloadUrl;
};

export const uploadFileToStorage = async (
  file: File,
  uid: string,
  uploadId: number,
  assistantId: number,
) => {
  const firebaseApp = getApp();

  const storageForCSVFiles = getStorage(
    firebaseApp,
    `gs://${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}-text-completions`,
  );
  const storageRef = ref(
    storageForCSVFiles,
    `users/${uid}/assistants/${assistantId}/uploads/${uploadId}/${file.name}`,
  );

  await uploadBytes(storageRef, file);
};

export const getFileDownloadURL = async (
  fileName: string,
  uploadId: number,
  uid: string,
  assistantId: number,
) => {
  const firebaseApp = getApp();

  const storageForCSVFiles = getStorage(
    firebaseApp,
    `gs://${process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID}-text-completions`,
  );
  const storageRef = ref(
    storageForCSVFiles,
    `users/${uid}/assistants/${assistantId}/uploads/${uploadId}/result_${fileName}`,
  );

  return await getDownloadURL(storageRef);
};

export const updateVideoThumbnail = async ({
  thumbnail,
  uid,
  videoId,
}: {
  thumbnail: File;
  uid: string;
  videoId: number;
}) => {
  const storage = getStorage();

  const videoThumbnailRef = ref(storage, `video/${uid}/${videoId}/thumbnail`);

  const compressedBlob = await actionImgCompress('videoThumbnail', thumbnail);
  const convertedFile = new File([compressedBlob], 'thumbnail', {
    type: 'image/webp',
  });
  await uploadBytes(videoThumbnailRef, convertedFile);

  const videoThumbnailUrl = await getDownloadURL(videoThumbnailRef);

  return videoThumbnailUrl;
};

export const uploadGalleryImage = async ({
  image,
  uid,
  imageId,
}: {
  image: File;
  uid: string;
  imageId: number;
}) => {
  const storage = getStorage();

  const galleryImageRef = ref(storage, `gallery_images/${uid}/${imageId}`);

  const compressedBlob = await actionImgCompress('galleryImage', image);
  const convertedFile = new File([compressedBlob], `${imageId}`, {
    type: 'image/webp',
  });
  await uploadBytes(galleryImageRef, convertedFile);

  const galleryImageUrl = await getDownloadURL(galleryImageRef);

  return galleryImageUrl;
};
