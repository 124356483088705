import { useCallback, useEffect } from 'react';

import useActiveAuth from 'hooks/useActiveAuth';
import useAuth from 'hooks/useAuth';

import { LOG_OUT_EVENT, TRY_LOGIN_EVENT } from 'types/dataLayer';

import { addEventToDataLayer } from 'utils/forGTM/dataLayerHandlerForGTM';

// 데이터 레이어 관리를 위해 여기서 선언
// 현재 호출되는 곳은 로그아웃 되는 곳이라 반드시 참이 되어있음.
// ! 반드시 isProfileLoadProcessComplete 가 참이 되고나서 호출해야함.
export const useLogOutEventForGTM = () => {
  const logOutEventForGTM = useCallback(() => {
    addEventToDataLayer({
      event: LOG_OUT_EVENT,
      user_id: undefined,
      isSubscriber: undefined,
      trialing: undefined,
      managingChannel_id: undefined,
      managingChannel_isSubscriber: undefined,
      managingChannel_trialing: undefined,
      isMyAccount: undefined,
    });
  }, []);

  return logOutEventForGTM;
};

export const useTryLoginEventForGTM = () => {
  const { user: owner_user, isSubscribedUser: owner_isSubscribedUser } =
    useAuth();
  const {
    isProfileLoadProcessing,
    isAnonymousUser,
    role,
    user,
    isSubscribedUser,
  } = useActiveAuth();

  useEffect(() => {
    if (isProfileLoadProcessing) return;

    if (isAnonymousUser) {
      addEventToDataLayer({
        event: TRY_LOGIN_EVENT,
        user_id: undefined,
        isSubscriber: false,
        trialing: false,
      });
    } else {
      addEventToDataLayer({
        event: TRY_LOGIN_EVENT,
        isMyAccount: role === 'owner',

        user_id: owner_user?.id,
        isSubscriber: owner_isSubscribedUser,
        trialing: Boolean(owner_user?.membershipStatus === 'trialing'),

        managingChannel_id: user?.id,
        managingChannel_isSubscriber: isSubscribedUser,
        managingChannel_trialing: Boolean(
          user?.membershipStatus === 'trialing',
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileLoadProcessing]);
};
