import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { Box, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getCookie, setCookie } from 'cookies-next';
import { getMessaging, onMessage } from 'firebase/messaging';
import { useSnackbar } from 'notistack';

import { getCountry, patchUserActivity } from 'api/auth';

import useAuth from 'hooks/useAuth';
import useFetchUser from 'hooks/useFetchUser';
import useUserAuthentication from 'hooks/useUserAuthentication';

import type { NotificationMessageType } from 'types/notification';

import {
  checkNotificationEnabled,
  getFcmToken,
  isFCMSupportedBrowser,
  requestNotificationPermission,
  sendFcmTokenToServer,
} from 'utils/webPush';

const AuthService = ({
  children,
  customToken,
}: {
  children: ReactNode;
  customToken?: string;
}) => {
  const { locale, push, pathname } = useRouter();
  const {
    token,
    updateProfile,
    user: {
      uid,
      marketingNotificationEnabled,
      notificationEnabled,
      serviceNotificationEnabled,
    },
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useUserAuthentication(customToken);
  useFetchUser({
    role: 'owner',
  });

  const { mutate: patchActivityMutate } = useMutation({
    mutationFn: ({ idToken, uid }: { idToken: string; uid: string }) =>
      patchUserActivity(idToken, uid),
  });

  //TODO: 리렌더링을 일으킬 수 있는지 확인
  const userInfo = queryClient.getQueryData(['getUserFromSessionCookie']);

  useEffect(() => {
    if (uid && token) {
      patchActivityMutate({ idToken: token, uid: uid });
    }
  }, [patchActivityMutate, uid, token]);

  useEffect(() => {
    const setCountryForNonLoggedInUser = async () => {
      const country = await getCountry();
      setCookie('country', country, {
        maxAge: 60 * 60 * 24 * 365,
      });

      updateProfile('country', country);
    };

    const setLocaleForNonLoggedInUser = async () => {
      setCookie('locale', locale, {
        maxAge: 60 * 60 * 24 * 365,
      });
    };

    // 유저 정보에서 나라 정보를 받아올 수 없거나, 쿠키에 나라 정보가 없을 경우
    if (!userInfo && getCookie('country') === undefined) {
      setCountryForNonLoggedInUser();
    }

    if (getCookie('locale') === undefined) {
      setLocaleForNonLoggedInUser();
    }
  }, [locale, updateProfile, userInfo]);

  useEffect(() => {
    const initFcmSetting = async () => {
      const isFCMSupported = await isFCMSupportedBrowser();
      if (!isFCMSupported) return;

      try {
        await requestNotificationPermission();

        const fcmToken = await getFcmToken();
        if (!token || !fcmToken) return;

        await sendFcmTokenToServer(token, fcmToken);
      } catch (error) {
        console.error(error);
      }
    };

    const isControlAppPage = pathname.includes('control/app');
    if (token !== '' && !isControlAppPage) initFcmSetting();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const foregroundFCMMessaging = async () => {
      const isFCMSupported = await isFCMSupportedBrowser();
      if (!isFCMSupported) return;

      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        if (
          payload.data &&
          payload.data?.messageType &&
          notificationEnabled !== undefined &&
          serviceNotificationEnabled !== undefined &&
          marketingNotificationEnabled !== undefined &&
          checkNotificationEnabled(
            payload.data?.messageType as NotificationMessageType,
            notificationEnabled,
            serviceNotificationEnabled,
            marketingNotificationEnabled,
          )
        ) {
          const message = JSON.parse(payload.data.message);
          const { title, body } = locale === 'ko' ? message.ko : message.en;
          enqueueSnackbar(
            <Box
              onClick={() => {
                if (payload.data?.action) {
                  push(payload.data?.action);
                }
              }}
              sx={{ maxWidth: '50vw' }}
            >
              <Typography
                variant="body1"
                sx={{
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {body}
              </Typography>
            </Box>,
            {
              SnackbarProps: {
                style: {
                  cursor: payload.data?.action ? 'pointer' : 'default',
                },
              },
              variant: 'info',
              persist: true,
              autoHideDuration: 10000,
            },
          );
        }
      });
    };

    foregroundFCMMessaging();
  }, [
    enqueueSnackbar,
    locale,
    marketingNotificationEnabled,
    notificationEnabled,
    push,
    serviceNotificationEnabled,
  ]);

  return <>{children}</>;
};

export default AuthService;
