import { useEffect } from 'react';

import { Box, Switch } from '@mui/material';

import useActiveAuth from 'hooks/useActiveAuth';
import useToggle from 'hooks/useToggle';

import { USER_PROFILE_UPDATE } from 'types/auth';

const subscriptionInfoResult = {
  status: 'fulfilled',
  value: {
    uid: 'wdJU4hBv2IUJ3N97sikEcglYnmV2',
    isSubscriber: true,
    productId: 'pro_01h8k42fz3h0zr5vhf60jzaj9y',
    customerId: 'ctm_01hbymd6w560f4a7pschhayg7f',
    freeTrialUsed: true,
    payment: 'paddle_billing',
    priceId: 'pri_01h8k44h6ad0dfb7smvhvrr17h',
    subscriptionId: 'sub_01hzdta6x79a9h02hbx325q9wa',
    status: 'active',
    cancelId: null,
    transactionId: 'txn_01hzzv3trqch3wayebx1a08zrc',
  },
};

const SubscribeControlSwitch = () => {
  const { isOpen, toggle } = useToggle();

  const { updateSubscriptionState, dispatch } = useActiveAuth();
  useEffect(() => {
    if (isOpen) {
      updateSubscriptionState(subscriptionInfoResult.value);
      const isSubscribedUser =
        subscriptionInfoResult.value?.isSubscriber || false;
      dispatch({
        type: USER_PROFILE_UPDATE,
        payload: {
          isSubscribedUser,
        },
      });
    } else {
      updateSubscriptionState(null);
      dispatch({
        type: USER_PROFILE_UPDATE,
        payload: {
          isSubscribedUser: false,
        },
      });
    }
  }, [dispatch, isOpen, updateSubscriptionState]);

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      구독 상태 제어 <Switch checked={isOpen} onClick={toggle} />
    </Box>
  );
};

export default SubscribeControlSwitch;
