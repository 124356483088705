import type { PaletteMode } from '@mui/material';

export const BLACK = '#121212';
export const WHITE = '#ffffff';

export const magentaPalette = {
  900: '#520000',
  800: '#7A0000',
  700: '#A30000',
  600: '#CC0000',
  500: '#FF0000',
  400: '#FF2E2E',
  300: '#FF5C5C',
  200: '#FF8A8A',
  100: '#FFB7B7',
  50: '#FFE5E5',
  25: '#FFF7F7',
};

const yellowPalette = {
  900: '#5C4000',
  800: '#855D00',
  700: '#AD7900',
  600: '#D69500',
  500: '#FFB200',
  400: '#FFC02E',
  300: '#FFD05C',
  200: '#FFDB8A',
  100: '#FFE5B7',
  50: '#FFF2E5',
  25: '#FFF8F2',
};

const bluePalette = {
  900: '#001F5C',
  800: '#002865',
  700: '#003BAD',
  600: '#0049D6',
  500: '#0157FF',
  400: '#2E75FF',
  300: '#5C95FF',
  200: '#8AB2FF',
  100: '#B8D0FF',
  50: '#E5EFFF',
  25: '#F2F7FF',
};

const greenPalette = {
  900: '#004B1C',
  800: '#006D29',
  700: '#008F36',
  600: '#00B142',
  500: '#00D34F',
  400: '#12F567',
  300: '#3DFF86',
  200: '#68FFA0',
  100: '#92FFBB',
  50: '#BDFFD5',
  25: '#E7FFF0',
};

export const primaryPalette = {
  900: '#0A2610',
  800: '#164D22',
  700: '#217533',
  600: '#2C9D45',
  500: '#37C556',
  400: '#5DD277',
  300: '#85DD98',
  200: '#ADE8BA',
  100: '#D5F3DB',
  50: '#E9F9EC',
  25: '#F4FCF6',
};

export const secondaryPalette = {
  1: '#93DC00',
  2: '#15B3B3',
  3: '#2362A2',
};

/**
 * @deprecated
 * 더 이상 사용되지 않는 색상 팔레트입니다. grayPalette를 사용해주세요.
 */
export const deprecatedGrayPalette = {
  light: {
    0: '#000000',
    5: '#101011',
    9: '#17181A',
    10: '#1D1D21',
    15: '#27282F',
    20: '#40404B',
    30: '#5B5D69',
    40: '#6E717E',
    60: '#AAABBA',
    70: '#D1D2D7',
    80: '#DDDEE1',
    85: '#E8E9EC',
    90: '#F1F1F1',
    95: '#F6F6F6',
    99: '#FCFCFC',
  },
  dark: {
    0: '#000000',
    5: '#101011',
    9: '#17181A',
    10: '#1D1D21',
    15: '#27282F',
    20: '#40404B',
    30: '#5B5D69',
    40: '#6E717E',
    60: '#AAABBA',
    70: '#D1D2D7',
    80: '#DDDEE1',
    85: '#E8E9EC',
    90: '#F1F1F1',
    95: '#F6F6F6',
    99: '#FCFCFC',
  },
};

export const grayPalette = {
  light: {
    25: '#F9F9FA',
    50: '#F3F3F5',
    100: '#E5E5E9',
    200: '#CACAD1',
    300: '#AEAEBA',
    400: '#9393A2',
    500: '#78788A',
    600: '#60606F',
    700: '#484854',
    800: '#313138',
    900: '#19191d',
  },
  dark: {
    25: '#19191d',
    50: '#212125',
    100: '#313138',
    200: '#484854',
    300: '#60606F',
    400: '#78788A',
    500: '#9393A2',
    600: '#AEAEBA',
    700: '#CACAD1',
    800: '#E5E5E9',
    900: '#F3F3F5',
  },
};

const opacityPalette = {
  10: 'rgba(23, 23, 25,  0.08)',
  15: 'rgba(23, 23, 25,  0.12)',
  20: 'rgba(23, 23, 25,  0.16)',
  25: 'rgba(23, 23, 25,  0.22)',
  30: 'rgba(23, 23, 25,  0.28)',
  35: 'rgba(23, 23, 25,  0.35)',
  40: 'rgba(23, 23, 25,  0.43)',
  45: 'rgba(23, 23, 25,  0.52)',
  50: 'rgba(23, 23, 25,  0.61)',
  60: 'rgba(23, 23, 25,  0.74)',
  70: 'rgba(23, 23, 25,  0.88)',
  80: 'rgba(23, 23, 25,  0.97)',
};

export const gradient = {
  primary: 'linear-gradient(115deg, #37C556 13.75%, #15B3B3 82.09%)',
  secondary:
    'linear-gradient(86deg, #B8E218 -4.7%, #37C556 43.88%, #2C9D45 100.27%)',
};

const teamCategoryColor = {
  light: {
    DEFAULT: {
      bar: grayPalette.light[100],
      bullet: grayPalette.light[200],
    },
    RED: {
      bar: '#FFD1D1',
      bullet: '#FF9292',
    },
    ORANGE: {
      bar: '#FFDBC1',
      bullet: '#FFA15D',
    },
    LIME: {
      bar: '#DFF8CA',
      bullet: '#C0EE74',
    },
    GREEN: {
      bar: '#C1F2D2',
      bullet: '#86D2A0',
    },
    LIGHT_BLUE: {
      bar: '#CDEFFF',
      bullet: '#7ED2F9',
    },
    BLUE: {
      bar: '#D3E2FF',
      bullet: '#6F9CF4',
    },
    VIOLET: {
      bar: '#EBE5FF',
      bullet: '#AD99FA',
    },
    PINK: {
      bar: '#FFDFEA',
      bullet: '#FCA5C3',
    },
  },
  dark: {
    DEFAULT: {
      bar: grayPalette.dark[100],
      bullet: grayPalette.dark[200],
    },
    RED: {
      bar: '#FFB8B8',
      bullet: '#FFB8B8',
    },
    ORANGE: {
      bar: '#FFD1B0',
      bullet: '#FFD1B0',
    },
    LIME: {
      bar: '#D6F2BB',
      bullet: '#D6F2BB',
    },
    GREEN: {
      bar: '#A7F1BF',
      bullet: '#A7F1BF',
    },
    LIGHT_BLUE: {
      bar: '#BCE7FA',
      bullet: '#BCE7FA',
    },
    BLUE: {
      bar: '#B3CCFF',
      bullet: '#B3CCFF',
    },
    VIOLET: {
      bar: '#DBD2FF',
      bullet: '#DBD2FF',
    },
    PINK: {
      bar: '#FFC5D9',
      bullet: '#FFC5D9',
    },
  },
};

const palette = (mode: PaletteMode) => ({
  mode,
  ...(mode === 'light'
    ? {
        surface: {
          normal: grayPalette.light[50],
          hover: grayPalette.light[100],
          sub: grayPalette.light[25],
          disable: grayPalette.light[100],
          active: primaryPalette[500],
          activeSub: primaryPalette[100],
          warning: yellowPalette[25],
          dimmed: opacityPalette[20],
          alt25: WHITE,
          alt50: WHITE,

          calendar: WHITE,
        },
        text: {
          primary: BLACK,
          secondary: grayPalette.light[700],
          tertiary: grayPalette.light[500],
          disableFilled: grayPalette.light[300],
          disableOutlined: grayPalette.light[200],
          caption: grayPalette.light[400],
          active: primaryPalette[500],
          link: bluePalette[500],
          warning: yellowPalette[400],
          error: magentaPalette[600],

          bold: '#000000',
          headbar: '#f0f6fb',
          placeholder: '#999999',
          logoText: '#0f0f0f',
          third: grayPalette.light[500],
        },
        icon: {
          normal: grayPalette.light[500],
          secondary: grayPalette.light[400],
          emphasis: grayPalette.light[700],
          hover: grayPalette.light[100],
          active: primaryPalette[500],
          disable: grayPalette.light[300],
          warning: yellowPalette[400],
        },
        // ! 기본적으로 divider라는 값을 mui에서 color값으로 지정하고 있어서 변경이 안되는 듯 합니다.
        // ! 따라서 디자인 시안에는 divider로 되어있는데 사용할때는 dividers로 사용해주세요.
        dividers: {
          normal: grayPalette.light[100],
          emphasis: grayPalette.light[200],
        },
        border: {
          normal: grayPalette.light[200],
          hover: grayPalette.light[600],
          focus: primaryPalette[500],
          disable: grayPalette.light[200],
          warning: yellowPalette[200],

          tableCell: 'inset 0px -1px 0px rgba(62, 62, 62, 0.08)',
          tableRow: deprecatedGrayPalette[mode][70],
          calendar: grayPalette.light[100],
        },
        status: {
          information: bluePalette[500],
          warning: yellowPalette[400],
          critical: magentaPalette[500],
          success: greenPalette[500],
        },
        chip: {
          scheduled: bluePalette[400],
          register: primaryPalette[500],
          close: grayPalette.light[900],
          progress: magentaPalette[400],
        },
        badge: {
          scheduled: bluePalette[600],
          register: gradient.secondary,
        },

        primary: {
          main: primaryPalette[500],
          light: primaryPalette[400],
          dark: primaryPalette[600],
        },
        brighterPrimary: {
          main: primaryPalette[50],
          light: primaryPalette[50],
          dark: primaryPalette[100],
        },

        background: {
          default: '#ffffff',
          headbar: '#24292f',
          menubar: '#fafafa',
          sidebar: '#fafafa',
          landing: '#F9F9FF',
          box: '#f0f6fb',
          select: '#f0f6fc',
          paper: '#ffffff',
          comment: deprecatedGrayPalette[mode][90],
          tab: deprecatedGrayPalette[mode][95],
          tableHead: `linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), ${WHITE}`,
        },
      }
    : {
        surface: {
          normal: grayPalette.dark[50],
          hover: grayPalette.dark[100],
          sub: grayPalette.dark[25],
          disable: grayPalette.dark[900],
          active: primaryPalette[500],
          // primary500에 투명도 10%를 적용한 색상값입니다.
          activeSub: `${primaryPalette[500]}10`,
          warning: grayPalette.dark[100],
          dimmed: opacityPalette[60],
          alt25: grayPalette.dark[25],

          alt50: grayPalette.dark[50],
          calendar: grayPalette.dark[50],
        },
        text: {
          primary: WHITE,
          secondary: grayPalette.dark[700],
          tertiary: grayPalette.dark[500],
          disableFilled: grayPalette.dark[200],
          disableOutlined: grayPalette.dark[200],
          caption: grayPalette.dark[400],
          active: primaryPalette[500],
          link: bluePalette[400],
          warning: yellowPalette[600],
          error: magentaPalette[600],

          bold: '#ffffff',
          headbar: '#ffffff',
          placeholder: '#c2c3c4',
          logoText: '#ffffff',
          third: grayPalette.dark[500],
        },
        icon: {
          normal: grayPalette.dark[400],
          secondary: grayPalette.dark[500],
          emphasis: WHITE,
          hover: grayPalette.dark[800],
          active: primaryPalette[500],
          disable: grayPalette.dark[200],
          warning: yellowPalette[500],
        },
        dividers: {
          normal: grayPalette.dark[100],
          emphasis: grayPalette.dark[200],
        },
        border: {
          normal: grayPalette.dark[300],
          hover: grayPalette.dark[600],
          focus: primaryPalette[500],
          disable: grayPalette.dark[200],
          warning: yellowPalette[600],

          tableCell: 'inset 0px -1px 0px rgba(62, 62, 62, 0.25)',
          tableRow: deprecatedGrayPalette[mode][20],
          calendar: grayPalette.dark[200],
        },
        status: {
          information: bluePalette[500],
          warning: yellowPalette[400],
          critical: magentaPalette[500],
          success: greenPalette[500],
        },
        chip: {
          scheduled: bluePalette[400],
          register: primaryPalette[500],
          close: grayPalette.dark[50],
          progress: magentaPalette[400],
        },
        badge: {
          scheduled: bluePalette[600],
          register: gradient.secondary,
        },

        primary: {
          main: primaryPalette[500],
          light: primaryPalette[400],
          dark: primaryPalette[600],
        },
        brighterPrimary: {
          main: 'rgba(55, 197, 86, 0.2)',
          light: 'rgba(55, 197, 86, 0.1)',
          dark: 'rgba(55, 197, 86, 0.3)',
        },

        background: {
          default: '#121212',
          headbar: '#161b22',
          menubar: '#121212',
          sidebar: '#121212',
          landing: '#2C2C2D',
          box: '#2C2C2D',
          select: '#2C2C2A',
          paper: deprecatedGrayPalette[mode][15],
          comment: deprecatedGrayPalette[mode][10],
          tab: deprecatedGrayPalette[mode][15],
          tableHead: `linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), ${deprecatedGrayPalette[mode][15]}`,
        },
      }),
  gray: grayPalette[mode],
  deprecatedGray: deprecatedGrayPalette[mode],
  magenta: magentaPalette,
  primaryColor: primaryPalette,
  primaryGradientColor: gradient.primary,
  yellow: yellowPalette,
  blue: bluePalette,
  green: greenPalette,
  secondaryColor: secondaryPalette,
  secondaryGradientColor: gradient.secondary,
  adGradientColor: 'linear-gradient(115deg,#2362A2 13.75%,#15B3B3 82.09%)',
  white: WHITE,
  black: BLACK,
  themeModeColor: mode === 'light' ? WHITE : BLACK,
  themeInverseColor: mode === 'light' ? BLACK : WHITE,
  opacity: opacityPalette,
  teamCategoryColor: teamCategoryColor[mode],
});

export default palette;
