import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { Box } from '@mui/material';
import { setUser } from '@sentry/nextjs';

import SubscribeControlSwitch from 'components/commons/SubscribeControlSwitch';

import useActiveAuth from 'hooks/useActiveAuth';
import useSubscriptionStatusListener from 'hooks/useSubscriptionStatusListener';

const ActiveAuthService = ({ children }: { children: ReactNode }) => {
  const { channel, user, onUserSnapshotUpdate } = useActiveAuth();

  useEffect(() => {
    const username = channel?.channelName || user?.displayName;
    const id = user?.id;

    if (id && username)
      setUser({
        id,
        username,
      });
  }, [channel?.channelName, user?.displayName, user?.id]);

  useSubscriptionStatusListener({
    uid: user.id,
    onUserSnapshotUpdate: onUserSnapshotUpdate,
  });

  return (
    <Box>
      {process.env.NEXT_PUBLIC_STUDIO_NODE_ENV !== 'production' && (
        <SubscribeControlSwitch />
      )}
      {children}
    </Box>
  );
};

export default ActiveAuthService;
