import type { ReactNode } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import ShareIcon from '@mui/icons-material/Share';

import useClipboardCopy from 'hooks/useClipboardCopy';

type AppBarMenuListContextType = {
  menuList: {
    text: string;
    show?: boolean;
    startIcon?: ReactNode;
    onClick: () => void;
  }[];
  handleChangeMenuList: (
    menuList: AppBarMenuListContextType['menuList'],
  ) => void;
};

const AppBarMenuListContext = createContext<
  AppBarMenuListContextType | undefined
>(undefined);

const AppBarMenuListProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('common');
  const { shareUrl } = useClipboardCopy();

  const [menuList, setMenuList] = useState<
    AppBarMenuListContextType['menuList']
  >([
    {
      text: t('share'),
      startIcon: <ShareIcon />,
      show: true,
      onClick: () => {
        shareUrl(`${window.location.href}`);
      },
    },
  ]);

  const handleChangeMenuList = useCallback(
    (menuList: AppBarMenuListContextType['menuList']) => {
      const visibleMenuList = menuList.filter((menu) => menu.show !== false);
      setMenuList(visibleMenuList);
    },
    [],
  );

  const appBarMenuListContextValue = useMemo(() => {
    return {
      menuList,
      handleChangeMenuList,
    };
  }, [menuList, handleChangeMenuList]);

  return (
    <AppBarMenuListContext.Provider value={appBarMenuListContextValue}>
      {children}
    </AppBarMenuListContext.Provider>
  );
};

export { AppBarMenuListContext, AppBarMenuListProvider };
