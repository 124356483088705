import { useCallback, useMemo, useReducer, useState } from 'react';

import { getCookie, setCookie } from 'cookies-next';

import {
  getCountryAndLanguage,
  getStudioUserData,
  updateCountryAndLanguage,
} from 'api/auth';

import {
  AUTH_SUCCESS,
  type AuthState,
  type FirebaseActions,
  RESET,
  type ServerUser,
  UN_AUTHENTICATE,
  USER_PROFILE_LOAD_SUCCESS,
  USER_PROFILE_UPDATE,
  type initializeProfileType,
} from 'types/auth';
import type { ChannelDetailDataType } from 'types/channel';
import type { FireStoreStudioUserType } from 'types/firestore';

const initialState: AuthState = {
  isAuthenticatedUser: false,
  isSubscribedUser: false,
  isProfileLoadedUser: false,
  isAnonymousUser: false,

  isFirebaseAuthSuccess: false,
  isFirebaseAuthFail: false,
  isFirebaseAuthenticating: true,

  isUserProfileLoadSuccess: false,
  isUserProfileLoadFail: false,
  isUserProfileLoading: true,

  isProfileLoadProcessComplete: false,
  isProfileLoadProcessing: true,
  isAuthProcessComplete: false,
};

const reducer = (state: AuthState, action: FirebaseActions): AuthState => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        isAnonymousUser: false,
        isAuthenticatedUser: true,

        isFirebaseAuthFail: false,
        isFirebaseAuthSuccess: true,
        isFirebaseAuthenticating: false,

        isAuthProcessComplete: true,
      };
    case USER_PROFILE_LOAD_SUCCESS:
      return {
        ...state,
        isSubscribedUser: action.payload.isSubscribedUser,
        isProfileLoadedUser: true,

        isUserProfileLoadFail: false,
        isUserProfileLoadSuccess: true,
        isUserProfileLoading: false,

        isProfileLoadProcessComplete: true,
        isProfileLoadProcessing: false,
      };
    case USER_PROFILE_UPDATE:
      return {
        ...state,
        isSubscribedUser: action.payload.isSubscribedUser,
      };
    case RESET:
      return initialState;
    case UN_AUTHENTICATE:
      return {
        ...initialState,
        isAnonymousUser: true,
        isAuthProcessComplete: true,

        isFirebaseAuthenticating: false,

        isUserProfileLoading: false,

        isProfileLoadProcessComplete: true,
        isProfileLoadProcessing: false,
      };
    default:
      return state;
  }
};

const useAuthCommonValue = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [subscriptionState, setSubscriptionState] =
    useState<FireStoreStudioUserType | null>(null);

  const getDefaultProfileData = useCallback(() => {
    return {
      uid: '',
      userName: '',
      nickname: '',
      email: '',
      profileUrl: '',
      country: '',
      language: '',
      autoPlayEnabled: false,
      cookieAllowed: false,
      notificationEnabled: false,
      serviceNotificationEnabled: false,
      marketingNotificationEnabled: false,
      paymentAvailability: true,
    };
  }, []);

  const [profile, setProfile] = useState<ServerUser | undefined>(
    getDefaultProfileData(),
  );
  const [isUserDataLoading, setIsUserDataLoading] = useState<boolean>(false);

  const getDefaultChannelData = (
    channelInfo?: ChannelDetailDataType | null,
  ) => {
    return {
      uid: '',
      channelId: 0,
      nickname: '',
      channelName: '',
      channelDescription: null,
      bannerImageUrl: '',
      category: null,
      interests: [],
      channelLink: [],
      channelSections: [],
      isSubscriber: false,

      placeId: null,
      address: null,
      detailAddress: null,

      createdAt: '',
      updatedAt: '',
      followerCount: 0,
      matchCount: 0,
      eventCount: 0,
      teamCount: 0,
      videoCount: 0,
      playlistCount: 0,
      galleryImageCount: 0,
      postCount: 0,

      userName: '',
      profileUrl: '',

      autoApproval: false,
      useQuestion: false,
      joinQuestion: '',
      teamMenuEnabled: true,
      teamIntroductionMenuEnabled: true,
      teamScheduleMenuEnabled: true,
      teamMemberMenuEnabled: true,

      ...channelInfo,
    };
  };

  const [channel, setChannel] = useState<Omit<ChannelDetailDataType, 'userId'>>(
    () => getDefaultChannelData(null),
  );

  const setCountryCookie = useCallback((countryValue: string | undefined) => {
    if (countryValue === undefined) return;
    setCookie('country', countryValue, {
      maxAge: 60 * 60 * 24 * 365 * 1000,
    });
  }, []);

  const checkLocalConsent = useCallback(
    async (currentCookieAllowed: boolean | null) => {
      const localConsentFromCookie = getCookie('localConsent');

      if (Boolean(localConsentFromCookie) !== Boolean(currentCookieAllowed)) {
        setCookie('localConsent', `${currentCookieAllowed}`, {});
      }
    },
    [],
  );

  const initializeProfile = useCallback(
    async ({ token, uid, role, manageChannelUid }: initializeProfileType) => {
      const studioUserData = await getStudioUserData(
        token,
        uid,
        manageChannelUid,
      );

      checkLocalConsent(studioUserData.cookieAllowed);

      const isEmptyCountryOrLanguage =
        !studioUserData.country || !studioUserData.language;

      if (role !== 'manager' && isEmptyCountryOrLanguage) {
        const { country, language } = await getCountryAndLanguage();
        await updateCountryAndLanguage(token, uid, country, language);

        setProfile({
          ...studioUserData,
          country,
          language,
        });

        setCountryCookie(country);
        return studioUserData;
      }

      setProfile(studioUserData);

      if (getCookie('country') === studioUserData.country)
        return studioUserData;

      setCountryCookie(studioUserData.country);

      return studioUserData;
    },
    [checkLocalConsent, setCountryCookie],
  );

  const updateProfile = useCallback(
    (
      type:
        | 'userName'
        | 'country'
        | 'language'
        | 'profileUrl'
        | 'nickname'
        | 'uid',
      newData: string,
    ) => {
      setProfile((prevProfile) => {
        if (prevProfile) return { ...prevProfile, [type]: newData };
      });

      if (type === 'country' && getCookie('country') !== newData)
        setCookie('country', newData, { maxAge: 60 * 60 * 24 * 365 * 1000 });
    },
    [],
  );

  const updateAutoPlayEnabled = useCallback((isAutoPlay: boolean) => {
    setProfile((prevProfile) => {
      if (prevProfile) return { ...prevProfile, autoPlayEnabled: isAutoPlay };
    });
  }, []);

  const updateCookieAllowed = useCallback((isAllowed: boolean) => {
    setProfile((prevProfile) => {
      if (prevProfile) return { ...prevProfile, cookieAllowed: isAllowed };
    });

    localStorage.setItem('localConsent', `${isAllowed}`);
  }, []);

  const updateNotificationEnabled = useCallback((isNotification: boolean) => {
    setProfile((prevProfile) => {
      if (prevProfile)
        return { ...prevProfile, notificationEnabled: isNotification };
    });
  }, []);

  const updateServiceNotificationEnabled = useCallback(
    (isServiceNotification: boolean) => {
      setProfile((prevProfile) => {
        if (prevProfile)
          return {
            ...prevProfile,
            serviceNotificationEnabled: isServiceNotification,
          };
      });
    },
    [],
  );

  const updateMarketingNotificationEnabled = useCallback(
    (isMarketingNotification: boolean) => {
      setProfile((prevProfile) => {
        if (prevProfile)
          return {
            ...prevProfile,
            marketingNotificationEnabled: isMarketingNotification,
          };
      });
    },
    [],
  );

  const updatePaymentAvailability = useCallback(
    (paymentAvailable: boolean | undefined | null) => {
      setProfile((prevProfile) => {
        if (prevProfile)
          return {
            ...prevProfile,
            paymentAvailability: paymentAvailable === false ? false : true,
          };
      });
    },
    [],
  );

  const updateChannel = useCallback(
    (
      type: keyof ChannelDetailDataType,
      newData: ChannelDetailDataType[keyof ChannelDetailDataType],
    ) => {
      setChannel((prevChannel) => ({
        ...prevChannel,
        [type]: newData,
      }));
    },
    [],
  );

  const updateChannelData = useCallback(
    (channelInfo: Partial<ChannelDetailDataType>) => {
      setChannel((prevChannel) => ({
        ...prevChannel,
        ...channelInfo,
      }));
    },
    [],
  );
  const onUserSnapshotUpdate = useCallback((data: FireStoreStudioUserType) => {
    setSubscriptionState(data?.isSubscriber ? data : null);
    dispatch({
      type: USER_PROFILE_UPDATE,
      payload: {
        isSubscribedUser: !!data?.isSubscriber,
      },
    });
  }, []);

  const resetContext = useCallback(() => {
    setProfile(undefined);
    setChannel(getDefaultChannelData());
    dispatch({
      type: RESET,
    });
  }, []);

  const updateSubscriptionState = useCallback(
    (subscriptionState: FireStoreStudioUserType | null) => {
      setSubscriptionState(subscriptionState);
    },
    [],
  );

  const updateUserDataLoading = useCallback((isLoading: boolean) => {
    setIsUserDataLoading(isLoading);
  }, []);

  const authContextValue = useMemo(
    () => ({
      channel,
      dispatch,
      getDefaultProfileData,
      initializeProfile,
      isUserDataLoading,
      onUserSnapshotUpdate,
      profile,
      resetContext,
      setChannel: updateChannelData,
      setProfile,
      state,
      subscriptionState,
      updateAutoPlayEnabled,
      updateChannel,
      updateCookieAllowed,
      updateMarketingNotificationEnabled,
      updateNotificationEnabled,
      updatePaymentAvailability,
      updateProfile,
      updateServiceNotificationEnabled,
      updateSubscriptionState,
      updateUserDataLoading,
    }),
    [
      channel,
      getDefaultProfileData,
      initializeProfile,
      isUserDataLoading,
      onUserSnapshotUpdate,
      profile,
      resetContext,
      state,
      subscriptionState,
      updateAutoPlayEnabled,
      updateChannel,
      updateChannelData,
      updateCookieAllowed,
      updateMarketingNotificationEnabled,
      updateNotificationEnabled,
      updatePaymentAvailability,
      updateProfile,
      updateServiceNotificationEnabled,
      updateSubscriptionState,
      updateUserDataLoading,
    ],
  );

  return authContextValue;
};

export default useAuthCommonValue;
