import type { UserRecord } from 'firebase-admin/lib/auth/user-record';

import {
  authAxios,
  hostAxios,
  studioPublicApiAxiosV1,
  studioPublicAxiosV1,
} from 'lib/axios';

import type { ChannelDetailDataType, ChannelPagesType } from 'types/channel';

export const getChannelDetailData = async (handleOrNickname: string) =>
  await studioPublicApiAxiosV1<ChannelDetailDataType>({
    method: 'GET',
    url: `/channels/${handleOrNickname[0] === '@' ? handleOrNickname : `@${handleOrNickname}`}`,
  });

export const getIsManagedChannel = async ({
  uid,
  channelUid,
}: {
  uid: string;
  channelUid: string;
}) =>
  await studioPublicAxiosV1<boolean>({
    method: 'GET',
    url: '/channel/permission/check',
    params: {
      channelUid,
      uid,
    },
  });

export const getRecommendChannelList = async (
  token: string,
  queries: string,
): Promise<ChannelPagesType> =>
  await authAxios({
    token,
    serverType: 'studio',
    version: 'v1',
  })({
    method: 'GET',
    url: `/channel/recommend${queries}`,
  });

export const getChannelSearchResult = async (
  keyword: string,
  offset = 0,
  limit = 24,
) =>
  await studioPublicAxiosV1<ChannelPagesType>({
    method: 'GET',
    url: `/channel?limit=${limit}&offset=${offset}&q=${keyword}`,
  });

export const getManager = async (uid: string) =>
  await hostAxios<UserRecord>('/api/firebase/get_manager_with_uid', {
    headers: {
      uid,
    },
  });

export const getChannelStatistics = async (channelId: number) =>
  await studioPublicApiAxiosV1<{
    cumulativeContentViews: number;
    cumulativeContentRevenue?: number; // 마라톤 채널에만 임시 적용
  }>({
    method: 'GET',
    url: `/channel-statistics/${channelId}`,
  });
