import type { ReactNode } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

export type FilterType = 'all' | 'url' | 'file';

export type SortType = {
  orderByCreatedAt?: 'ASC' | 'DESC';
  orderByUpdatedAt?: 'ASC' | 'DESC';
  orderByViews?: 'ASC' | 'DESC';
  orderByLikes?: 'ASC' | 'DESC';
  orderByTitle?: 'ASC' | 'DESC';
};

interface VideoFilterAndSortContextType {
  filter: FilterType;
  sort: SortType;
  updateFilter: (filter: FilterType) => void;
  updateSort: (sort: SortType) => void;
  page: number;
  updatePage: (page: number) => void;
}

const VideoFilterAndSortContext = createContext<
  VideoFilterAndSortContextType | undefined
>(undefined);

const VideoFilterAndSortProvider = ({ children }: { children: ReactNode }) => {
  const [filter, setFilter] = useState<FilterType>('all');
  const [sort, setSort] = useState<SortType>({ orderByCreatedAt: 'DESC' });
  const [page, setPage] = useState(0);

  const updateFilter = useCallback((filter: FilterType) => {
    setFilter(filter);
  }, []);

  const updateSort = useCallback((sort: SortType) => {
    setSort(sort);
  }, []);

  const updatePage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const value = useMemo(
    () => ({
      filter,
      sort,
      updateFilter,
      updateSort,
      updatePage,
      page,
    }),
    [filter, page, sort, updateFilter, updatePage, updateSort],
  );

  return (
    <VideoFilterAndSortContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </VideoFilterAndSortContext.Provider>
  );
};

export { VideoFilterAndSortContext, VideoFilterAndSortProvider };
