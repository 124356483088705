import type { ReactNode } from 'react';
import { createContext, useCallback, useMemo } from 'react';

import useTranslation from 'next-translate/useTranslation';

import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import CustomCircularProgress from 'components/atoms/CustomCircularProgress';

import { useLogOutEventForGTM } from 'hooks/forGTM/useTryLoginEventForGTM';
import useAuth from 'hooks/useAuth';
import useManagerAuth from 'hooks/useManagerAuth';

import { type ActiveAuthContextType } from 'types/auth';
import type { ManagedChannelOwnerType } from 'types/channel';

import iOSCommunication from 'utils/iOSCommunication';
import sendMessageToServiceWorker from 'utils/sendMessageToServiceWorker';

const ActiveAuthContext = createContext<ActiveAuthContextType | null>(null);

const ActiveAuthProvider = ({ children }: { children: ReactNode }) => {
  const loginUserAuth = useAuth();
  const managerAuth = useManagerAuth();
  const { t } = useTranslation('common');
  const logOutEventForGTM = useLogOutEventForGTM();

  const getIsLoginUser = useCallback(
    (uid: string) => loginUserAuth.user?.id === uid,
    [loginUserAuth.user?.id],
  );

  const changeActiveAccount = useCallback(
    async (channelInfo: ManagedChannelOwnerType) => {
      managerAuth.resetContext();
      if (getIsLoginUser(channelInfo.owner.uid)) {
        await new Promise((resolve) => setTimeout(resolve, 800));
        await managerAuth.clearManagedContext();
      } else {
        await managerAuth.updateManagedAccount(channelInfo);
      }
    },
    [getIsLoginUser, managerAuth],
  );

  const signOut = useCallback(async () => {
    try {
      await Promise.all([
        axios.post('/api/firebase/clear_cookie'),
        axios.delete('/api/firebase/clear_managed_account_cookie'),
      ]);

      if (iOSCommunication.isIOSAPP) iOSCommunication.requestDeleteFCMToken();

      logOutEventForGTM();

      await sendMessageToServiceWorker('CLEAR_NEXT_DATA_CACHE');

      window.location.href = '/';
    } catch {
      enqueueSnackbar(t('failedToLogout'), { variant: 'error' });
    }
  }, [logOutEventForGTM, t]);

  const activeAuthContextValue = useMemo(() => {
    const role = loginUserAuth.isAuthenticatedUser
      ? managerAuth.isAuthenticatedUser
        ? ('manager' as const)
        : ('owner' as const)
      : ('guest' as const);

    const authData = role === 'manager' ? managerAuth : loginUserAuth;

    const { signIn, token } = loginUserAuth;
    const { clearManagedContext, updateManagedAccount } = managerAuth;

    return {
      ...authData,
      role,
      changeActiveAccount,
      clearManagedContext,
      getIsLoginUser,
      signIn,
      signOut,
      token,
      updateManagedAccount,
    };
  }, [
    changeActiveAccount,
    getIsLoginUser,
    loginUserAuth,
    managerAuth,
    signOut,
  ]);

  return (
    <ActiveAuthContext.Provider value={activeAuthContextValue}>
      <CustomCircularProgress
        isLoading={
          loginUserAuth.isFirebaseAuthenticating
            ? false
            : managerAuth.initialLoading
              ? false
              : managerAuth.isFirebaseAuthenticating
        }
      />
      {children}
    </ActiveAuthContext.Provider>
  );
};

export { ActiveAuthContext, ActiveAuthProvider };
