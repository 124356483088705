import { useCallback } from 'react';

import useTranslation from 'next-translate/useTranslation';

import type { OptionsObject } from 'notistack';
import { enqueueSnackbar } from 'notistack';

import useActiveAuth from './useActiveAuth';

const useClipboardCopy = () => {
  const { t } = useTranslation('common');
  const { user } = useActiveAuth();

  const clipboardCopy = useCallback(
    async (
      text: string,
      {
        successMessage,
        failMessage,
        ...snackbarOptions
      }: {
        successMessage?: string;
        failMessage?: string;
      } & OptionsObject = {},
    ) => {
      try {
        if (navigator.clipboard) {
          await navigator.clipboard.writeText(text);
        } else {
          const tempClipboard = document.createElement('textarea');
          document.body.appendChild(tempClipboard);
          tempClipboard.value = text;
          tempClipboard.select();
          document.execCommand('copy');
          document.body.removeChild(tempClipboard);
        }
        enqueueSnackbar(successMessage || t('clipboardCopy.success'), {
          variant: 'info',
          autoHideDuration: 3000,
        });
      } catch {
        enqueueSnackbar(failMessage || t('clipboardCopy.fail'), {
          variant: 'error',
          autoHideDuration: 5000,
          ...snackbarOptions,
        });
      }
    },
    [t],
  );

  const removeEn = useCallback((text: string) => {
    // 링크 중간에 있는 /en/ 제거
    const withoutMiddleEn = text.replace(/\/en\//g, '/');

    //링크 끝에 있는 /en 제거 (메인페이지)
    return withoutMiddleEn.replace(/\/en$/, '');
  }, []);

  const shareUrl = useCallback(
    async (baseUrl: string) => {
      const utmQueries = `?utm_medium=url&utm_source=studio_shared_icon&id=${user.id ?? '비로그인'}`;
      const url = removeEn(baseUrl) + utmQueries;

      try {
        await navigator.share({ url });
      } catch (err) {
        if (!String(err).includes('AbortError')) {
          clipboardCopy(url);
        }
      }
    },
    [clipboardCopy, removeEn, user.id],
  );

  return { clipboardCopy, shareUrl };
};

export default useClipboardCopy;
