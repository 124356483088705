import type { ReactNode } from 'react';
import { createContext, useCallback, useMemo, useReducer } from 'react';

export interface VideoUploadStatusType {
  uploadStatus: string;
  progress: number;
}
interface VideosUploadStatusType {
  [index: number]: VideoUploadStatusType;
}

interface VideoUploadStatusContextType {
  videoUploadStatus: VideosUploadStatusType;
  startVideoUpload: (videoId: number) => void;
  updateVideoUploadProgress: (videoId: number, progress: number) => void;
  completeVideoUpload: (videoId: number) => void;
  failVideoUpload: (videoId: number) => void;
}

const VideoUploadStatusContext =
  createContext<VideoUploadStatusContextType | null>(null);

type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type VideoUploadStatusPayload = {
  START_VIDEO_UPLOAD: { videoId: number };
  UPDATE_VIDEO_UPLOAD_PROGRESS: { videoId: number; progress: number };
  COMPLETE_VIDEO_UPLOAD: { videoId: number };
  FAIL_VIDEO_UPLOAD: { videoId: number };
};

type VideoUploadStatusActions =
  ActionMap<VideoUploadStatusPayload>[keyof ActionMap<VideoUploadStatusPayload>];

const reducer = (
  state: VideosUploadStatusType,
  action: VideoUploadStatusActions,
) => {
  const videoId = action.payload.videoId;

  switch (action.type) {
    case 'START_VIDEO_UPLOAD':
      return {
        ...state,
        [videoId]: {
          uploadStatus: 'uploading',
          progress: 0,
        },
      };
    case 'UPDATE_VIDEO_UPLOAD_PROGRESS':
      return {
        ...state,
        [videoId]: {
          uploadStatus: 'uploading',
          progress: action.payload.progress,
        },
      };
    case 'COMPLETE_VIDEO_UPLOAD':
      return {
        ...state,
        [videoId]: {
          uploadStatus: 'complete',
          progress: 100,
        },
      };
    case 'FAIL_VIDEO_UPLOAD':
      return {
        ...state,
        [videoId]: {
          uploadStatus: 'fail',
          progress: 0,
        },
      };
    default:
      return state;
  }
};

const VideoUploadStatusProvider = ({ children }: { children: ReactNode }) => {
  const [videoUploadStatus, dispatch] = useReducer(reducer, {});

  const startVideoUpload = useCallback((videoId: number) => {
    dispatch({
      type: 'START_VIDEO_UPLOAD',
      payload: { videoId },
    });
  }, []);

  const updateVideoUploadProgress = useCallback(
    (videoId: number, progress: number) =>
      dispatch({
        type: 'UPDATE_VIDEO_UPLOAD_PROGRESS',
        payload: { videoId, progress },
      }),
    [],
  );

  const completeVideoUpload = useCallback(
    (videoId: number) =>
      dispatch({
        type: 'COMPLETE_VIDEO_UPLOAD',
        payload: { videoId },
      }),
    [],
  );

  const failVideoUpload = useCallback(
    (videoId: number) =>
      dispatch({
        type: 'FAIL_VIDEO_UPLOAD',
        payload: { videoId },
      }),
    [],
  );

  const contextValue = useMemo(
    () => ({
      videoUploadStatus,
      startVideoUpload,
      updateVideoUploadProgress,
      completeVideoUpload,
      failVideoUpload,
    }),
    [
      completeVideoUpload,
      failVideoUpload,
      startVideoUpload,
      updateVideoUploadProgress,
      videoUploadStatus,
    ],
  );

  return (
    <VideoUploadStatusContext.Provider value={contextValue}>
      {children}
    </VideoUploadStatusContext.Provider>
  );
};

export { VideoUploadStatusContext, VideoUploadStatusProvider };
