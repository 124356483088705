export interface NotificationData {
  id: number;
  createdAt: string;
  updatedAt: string;
  isRead: boolean;
  notificationEvent: NotificationEvent;
}

export interface NotificationEvent {
  id: number;
  title: string;
  body: string;
  imageUrl: string;
  actionUrl: string;
  eventType: string;
  messageType: NotificationMessageType;
  createdAt: string;
  updatedAt: string;
  channelName: string;
  // matchTitle, eventTitle은 상황에 따라 한개만 보내집니다.
  matchTitle?: string;
  eventTitle?: string;
  studioEventType: StudioEventType;
  videoTitle?: string;
  post?: string;
}

export enum NotificationMessageType {
  MYACTIVITY = 'my_activity',
  SOCIAL = 'social',
  SYSTEM = 'system',
  MARKETING = 'marketing',
}

export enum StudioEventType {
  SCOREBOARD_CREATED = 'scoreboard_created',
  SCOREBOARD_LIVE = 'scoreboard_live',
  SCOREBOARD_END = 'scoreboard_end',
  SCOREBOARD_SCHEDULE = 'scoreboard_schedule',
  SCOREBOARD_REMIND = 'scoreboard_remind',
  EVENT_CREATED = 'event_created',
  EVENT_PROGRESS = 'event_progress',
  EVENT_REGISTER = 'event_register',
  EVENT_END = 'event_end',
  FOLLOWING = 'following',
  LIKE = 'like',
  CHANNEL_PERMISSION_REQUEST = 'channel_permission_request',
  CHANNEL_PERMISSION_ACCEPTED = 'channel_permission_accepted',
  CHANNEL_PERMISSION_RESTRICTED = 'channel_permission_restricted',
  SYSTEM = 'system',
  MARKETING = 'marketing',
  SCOREBOARD_INCORRECT = 'scoreboard_incorrect',
  CHANNEL_MEMBERSHIP_ACCEPTED = 'channel_membership_accepted',
  CHANNEL_MEMBERSHIP_FORCED_WITHDRAW = 'channel_membership_forced_withdraw',
  CHANNEL_MEMBERSHIP_APPLY = 'channel_membership_apply',
  CHANNEL_MEMBERSHIP_COMPLETE = 'channel_membership_complete',
  CHANNEL_MEMBERSHIP_MATCH_UPLOADED = 'membership_match_uploaded',
  CHANNEL_MEMBERSHIP_EVENT_UPLOADED = 'membership_event_uploaded',
  CHANNEL_MEMBERSHIP_VIDEO_UPLOADED = 'membership_video_uploaded',
  CHANNEL_MEMBERSHIP_POST_UPLOADED = 'membership_post_uploaded',
}

export enum Tab {
  Notifications = 'notifications',
  Ads = 'ads',
}
