import { memo } from 'react';

import Script from 'next/script';

const GoogleMapScript = () => {
  return (
    <Script
      async
      src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_FIREBASE_API_KEY}&loading=async&libraries=places&callback=Function.prototype`}
    />
  );
};

export default memo(GoogleMapScript);
