import type { ReactNode } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import useAuthCommonValue from 'hooks/useAuthCommonValue';

import { type AuthContextType } from 'types/auth';

import generateTraceParent from 'utils/generateTraceParent';

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { locale, push } = useRouter();

  const {
    channel,
    dispatch,
    getDefaultProfileData,
    initializeProfile,
    onUserSnapshotUpdate,
    profile,
    resetContext,
    setChannel,
    setProfile,
    state,
    subscriptionState,
    updateAutoPlayEnabled,
    updateChannel,
    updateCookieAllowed,
    updateMarketingNotificationEnabled,
    updateNotificationEnabled,
    updatePaymentAvailability,
    updateProfile,
    updateServiceNotificationEnabled,
    updateSubscriptionState,
    isUserDataLoading,
    updateUserDataLoading,
  } = useAuthCommonValue();

  const [token, setToken] = useState<string>('');

  const signIn = useCallback(() => {
    const traceparent = generateTraceParent();

    push({
      pathname: process.env.NEXT_PUBLIC_CAMERAFI_LOGIN_URL,
      query: {
        callbackUrl: window.location.origin + `/${locale}`,
        platform: 'web',
        traceparent,
      },
    });
  }, [locale, push]);

  const isMembershipUser = subscriptionState && 'payment' in subscriptionState;

  const updateToken = useCallback(
    (newToken: string) => {
      setToken(newToken);
    },
    [setToken],
  );

  const authContextValue = useMemo(
    () => ({
      ...state,
      user: {
        autoPlayEnabled: profile?.autoPlayEnabled ?? false,
        cookieAllowed: profile?.cookieAllowed,
        country: profile?.country,
        displayName: profile?.userName,
        email: profile?.email,
        id: profile?.uid,
        language: profile?.language,
        marketingNotificationEnabled:
          profile?.marketingNotificationEnabled ?? false,
        membershipStatus: isMembershipUser
          ? subscriptionState.status
          : undefined,
        nickname: profile?.nickname,
        notificationEnabled: profile?.notificationEnabled ?? false,
        paymentAvailability: profile?.paymentAvailability ?? true,
        photoURL: profile?.profileUrl,
        serviceNotificationEnabled:
          profile?.serviceNotificationEnabled ?? false,
        subscriptionId: isMembershipUser
          ? subscriptionState.subscriptionId
          : undefined,
        isEnterpriseUser: subscriptionState?.isEnterprise ?? false,
      },
      channel,
      dispatch,
      getDefaultProfileData,
      initializeProfile,
      onUserSnapshotUpdate,
      resetContext,
      setChannel,
      setProfile,
      signIn,
      token,
      updateAutoPlayEnabled,
      updateChannel,
      updateCookieAllowed,
      updateMarketingNotificationEnabled,
      updateNotificationEnabled,
      updatePaymentAvailability,
      updateProfile,
      updateServiceNotificationEnabled,
      isUserDataLoading,
      updateSubscriptionState,
      updateToken,
      updateUserDataLoading,
    }),
    [
      channel,
      dispatch,
      getDefaultProfileData,
      initializeProfile,
      isMembershipUser,
      onUserSnapshotUpdate,
      profile?.autoPlayEnabled,
      profile?.cookieAllowed,
      profile?.country,
      profile?.email,
      profile?.language,
      profile?.marketingNotificationEnabled,
      profile?.nickname,
      profile?.notificationEnabled,
      profile?.paymentAvailability,
      profile?.profileUrl,
      profile?.serviceNotificationEnabled,
      profile?.uid,
      profile?.userName,
      resetContext,
      setChannel,
      setProfile,
      signIn,
      state,
      subscriptionState,
      token,
      updateAutoPlayEnabled,
      updateChannel,
      updateCookieAllowed,
      updateMarketingNotificationEnabled,
      updateNotificationEnabled,
      updatePaymentAvailability,
      updateProfile,
      updateServiceNotificationEnabled,
      updateSubscriptionState,
      isUserDataLoading,
      updateToken,
      updateUserDataLoading,
    ],
  );

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
