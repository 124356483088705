import type { ReactNode } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import axios from 'axios';
import { useSnackbar } from 'notistack';

import { getChannelDetailData, getIsManagedChannel } from 'api/channel';
import { getUserSubscriptionInfo } from 'api/firestore';

import useChangeChannelEventForGTM from 'hooks/forGTM/useChangeChannelEventForGTM';
import useAuth from 'hooks/useAuth';
import useAuthCommonValue from 'hooks/useAuthCommonValue';

import {
  AUTH_SUCCESS,
  type ManagerAuthContextType,
  UN_AUTHENTICATE,
  USER_PROFILE_LOAD_SUCCESS,
} from 'types/auth';
import type { ManagedChannelOwnerType } from 'types/channel';

const ManagerAuthContext = createContext<ManagerAuthContextType | null>(null);

const ManagerAuthProvider = ({ children }: { children: ReactNode }) => {
  const {
    token,
    user: { id: ownerUid },
  } = useAuth();
  const { changeOtherChannelEventForGTM, changeMyChannelEventForGTM } =
    useChangeChannelEventForGTM();
  const { t } = useTranslation('common');
  const { enqueueSnackbar } = useSnackbar();

  const [initialLoading, setInitialLoading] = useState<boolean>(false);

  const handleInitialLoading = useCallback((value: boolean) => {
    setInitialLoading(value);
  }, []);

  const {
    channel,
    dispatch,
    initializeProfile,
    onUserSnapshotUpdate,
    profile,
    resetContext,
    setChannel,
    state,
    subscriptionState,
    updateAutoPlayEnabled,
    updateChannel,
    updateCookieAllowed,
    updateMarketingNotificationEnabled,
    updateNotificationEnabled,
    updatePaymentAvailability,
    updateProfile,
    updateServiceNotificationEnabled,
    updateSubscriptionState,
    updateUserDataLoading,
    isUserDataLoading,
  } = useAuthCommonValue();

  const updateManagedAccount = useCallback(
    async (managedChannelInfo: ManagedChannelOwnerType) => {
      try {
        const managedChannelInfoUid = managedChannelInfo.owner.uid;

        const isManagedChannel = await getIsManagedChannel({
          uid: ownerUid,
          channelUid: managedChannelInfoUid,
        });

        if (!isManagedChannel) {
          throw new Error('Permission denied');
        }

        dispatch({
          type: AUTH_SUCCESS,
        });

        const [, channelInfo, , subscriptionInfo] = await Promise.all([
          initializeProfile({
            manageChannelUid: managedChannelInfoUid,
            role: 'manager',
            token,
            uid: managedChannelInfoUid,
          }),
          getChannelDetailData(managedChannelInfo.owner.nickname),
          axios({
            method: 'POST',
            url: `${location.origin}/api/firebase/create_managed_account_cookie`,
            data: { uid: managedChannelInfoUid },
          }),
          getUserSubscriptionInfo(managedChannelInfoUid),
        ]);

        changeOtherChannelEventForGTM({
          managingChannel_id: channelInfo?.uid,
          managingChannel_isSubscriber: subscriptionInfo?.isSubscriber,
          managingChannel_trialing:
            'freeTrialUsed' in subscriptionInfo
              ? subscriptionInfo.freeTrialUsed
              : false,
        });

        setChannel(channelInfo);
        updateSubscriptionState(subscriptionInfo);

        dispatch({
          type: USER_PROFILE_LOAD_SUCCESS,
          payload: {
            isSubscribedUser: true,
          },
        });
      } catch {
        dispatch({
          type: UN_AUTHENTICATE,
        });
        enqueueSnackbar(t('failedToChangeChannel'), {
          variant: 'error',
        });
      }
    },
    [
      changeOtherChannelEventForGTM,
      dispatch,
      enqueueSnackbar,
      initializeProfile,
      ownerUid,
      setChannel,
      t,
      token,
      updateSubscriptionState,
    ],
  );

  const clearManagedContext = useCallback(async () => {
    try {
      axios({
        method: 'DELETE',
        url: `${location.origin}/api/firebase/clear_managed_account_cookie`,
      });

      dispatch({ type: UN_AUTHENTICATE });

      changeMyChannelEventForGTM();
    } catch (e) {
      console.error(e);
    }
  }, [changeMyChannelEventForGTM, dispatch]);

  const isMembershipUser = subscriptionState && 'payment' in subscriptionState;

  const managerAuthContextValue = useMemo(
    () => ({
      ...state,
      user: {
        country: profile?.country,
        displayName: profile?.userName,
        email: profile?.email,
        nickname: profile?.nickname,
        id: profile?.uid,
        language: profile?.language,
        photoURL: profile?.profileUrl,
        membershipStatus: isMembershipUser
          ? subscriptionState.status
          : undefined,
        subscriptionId: isMembershipUser
          ? subscriptionState.subscriptionId
          : undefined,
        autoPlayEnabled: profile?.autoPlayEnabled ?? false,
        cookieAllowed:
          profile?.cookieAllowed === (undefined || null)
            ? undefined
            : profile?.cookieAllowed,
        notificationEnabled: profile?.notificationEnabled ?? false,
        serviceNotificationEnabled:
          profile?.serviceNotificationEnabled ?? false,
        marketingNotificationEnabled:
          profile?.marketingNotificationEnabled ?? false,
        paymentAvailability: profile?.paymentAvailability ?? true,
      },
      channel,
      isUserDataLoading,
      clearManagedContext,
      dispatch,
      handleInitialLoading,
      initialLoading,
      initializeProfile,
      onUserSnapshotUpdate,
      resetContext,
      setChannel,
      updateAutoPlayEnabled,
      updateChannel,
      updateCookieAllowed,
      updateManagedAccount,
      updateMarketingNotificationEnabled,
      updateNotificationEnabled,
      updatePaymentAvailability,
      updateUserDataLoading,
      updateProfile,
      updateServiceNotificationEnabled,
      updateSubscriptionState,
    }),
    [
      channel,
      clearManagedContext,
      dispatch,
      isUserDataLoading,
      handleInitialLoading,
      initialLoading,
      initializeProfile,
      isMembershipUser,
      onUserSnapshotUpdate,
      profile?.autoPlayEnabled,
      profile?.cookieAllowed,
      profile?.country,
      profile?.email,
      profile?.language,
      profile?.marketingNotificationEnabled,
      profile?.nickname,
      profile?.notificationEnabled,
      profile?.paymentAvailability,
      profile?.profileUrl,
      profile?.serviceNotificationEnabled,
      profile?.uid,
      profile?.userName,
      resetContext,
      setChannel,
      state,
      subscriptionState,
      updateAutoPlayEnabled,
      updateChannel,
      updateCookieAllowed,
      updateManagedAccount,
      updateMarketingNotificationEnabled,
      updateNotificationEnabled,
      updatePaymentAvailability,
      updateUserDataLoading,
      updateProfile,
      updateServiceNotificationEnabled,
      updateSubscriptionState,
    ],
  );

  return (
    <ManagerAuthContext.Provider value={managerAuthContextValue}>
      {children}
    </ManagerAuthContext.Provider>
  );
};

export { ManagerAuthContext, ManagerAuthProvider };
