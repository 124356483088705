// camelCase로 작성, 이벤트 이름 기반하여 태그매니저에서 태그를 생성하므로 작업자와 협의하여 작성
export const LOG_OUT_EVENT = 'logOut' as const;
export const TRY_LOGIN_EVENT = 'tryLogin' as const;
export const CHANNEL_CHANGE_EVENT = 'changeChannel' as const;
export const VIEW_PAGE_EVENT = 'viewPage' as const;
export const OPEN_SIGN_UP_POP_UP = 'openSignUpPopUp' as const;

type EventNameType =
  | typeof LOG_OUT_EVENT
  | typeof TRY_LOGIN_EVENT
  | typeof CHANNEL_CHANGE_EVENT
  | typeof VIEW_PAGE_EVENT
  | typeof OPEN_SIGN_UP_POP_UP;

export interface defaultEventType {
  event: EventNameType;
  platformType: 'web' | 'app_ios' | 'app_android';
}

export interface pageViewEvent {
  event: typeof VIEW_PAGE_EVENT;
  pageType: string; // 자동수집

  // 상세페이지
  contentId?: string; // 자동수집
  contentGroup?: string; // 자동수집
  contentOwnerUid?: string;

  // 채널 페이지
  isMyChannel?: boolean;

  // 경기, 이벤트 페이지
  sportType?: string;

  // 컨트롤 페이지
  source?: string;
}

export interface loginEvent {
  event:
    | typeof LOG_OUT_EVENT
    | typeof TRY_LOGIN_EVENT
    | typeof CHANNEL_CHANGE_EVENT;

  user_id?: string;
  isSubscriber?: boolean;
  trialing?: boolean;

  managingChannel_id?: string;
  managingChannel_isSubscriber?: boolean;
  managingChannel_trialing?: boolean;

  isMyAccount?: boolean;
}

export interface signUpPopUpEvent {
  event: typeof OPEN_SIGN_UP_POP_UP;
}
