
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import type { ReactElement, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { NextPage } from 'next';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { CssBaseline, GlobalStyles, IconButton } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { HydrationBoundary, QueryClient, QueryClientProvider, } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { parse } from 'cookie';
import { getApps, initializeApp } from 'firebase/app';
import { getAuth, inMemoryPersistence, setPersistence } from 'firebase/auth';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import 'regenerator-runtime/runtime';
import ErrorRefresh from 'components/organisms/ErrorRefresh';
import firebaseConfig from 'config/firebaseConfig';
import { AppBarMenuListProvider } from 'contexts/AppBarMenuListContext';
import { CookieProvider } from 'contexts/CookieContext';
import { DarkModeProvider } from 'contexts/DarkModeContext';
import { FeatureFlagProvider } from 'contexts/FeatureFlagContext';
import { HotkeyProvider } from 'contexts/HotkeyContext';
import { ScoreboardProvider } from 'contexts/ScoreboardContext';
import { VideoFilterAndSortProvider } from 'contexts/VideoFilterAndSortContext';
import { VideoUploadStatusProvider } from 'contexts/VideoUploadStatusContext';
import useIOSAPPInitSetting from 'hooks/iOSAPP/useIOSAPPInitSetting';
import useDarkMode from 'hooks/useDarkMode';
import GoogleAdsScript from 'lib/GoogleAdsScript';
import GoogleMapScript from 'lib/GoogleMapScript';
import GoogleTagmanagerScript from 'lib/GoogleTagmanagerScript';
import AuthenticationProviderStack from 'services/AuthenticationProviderStack';
import { getDesignTokens } from 'styles/theme';
import createEmotionCache from 'utils/createEmotionCache';
<GoogleTagmanagerScript />;
export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};
interface PageProps {
    theme: 'light' | 'dark';
    themeSource: 'system' | 'custom';
    dehydratedState?: unknown;
}
type AppPropsWithLayout = AppProps<PageProps> & {
    Component: NextPageWithLayout<PageProps>;
};
interface MuiProps {
    emotionCache?: EmotionCache;
}
interface CustomTokenProps {
    customToken?: string;
}
interface CookieProps {
    cookies: Record<string, string>;
}
const clientSideEmotionCache = createEmotionCache();
const globalStyle = (<GlobalStyles styles={(theme) => ({
        'html, body, #root': {
            height: '100%',
            padding: 0,
            margin: 0,
            boxSizing: 'border-box',
            fontFamily: theme.typography.fontFamily,
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            fontSize: theme.typography.fontSize,
            lineHeight: '1.8rem',
            wordBreak: 'keep-all',
            WebkitTextSizeAdjust: 'none',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
        },
        '#__next': {
            height: '100%',
        },
        a: {
            color: 'inherit',
            textDecoration: 'none',
        },
        '*': {
            boxSizing: 'border-box',
        },
        html: {
            overflowY: 'scroll',
        },
    })}/>);
if (!getApps().length) {
    initializeApp(firebaseConfig);
    setPersistence(getAuth(), inMemoryPersistence);
}
const ParentApp = (props: AppPropsWithLayout & MuiProps & CustomTokenProps & CookieProps) => {
    return (<DarkModeProvider cookieTheme={props.pageProps.theme} cookieThemeSource={props.pageProps.themeSource}>
      <MyApp {...props}/>
    </DarkModeProvider>);
};
ParentApp.getInitialProps = async (appContext: AppContext): Promise<AppInitialProps & MuiProps & CustomTokenProps & CookieProps> => {
    const appProps = await App.getInitialProps(appContext);
    if (typeof window === 'undefined') {
        const generateCustomToken = await import('utils/generate_custom_token');
        const customToken = await generateCustomToken.default(appContext.ctx);
        const cookies = parse(appContext.ctx.req?.headers.cookie || '');
        return { ...appProps, customToken, cookies };
    }
    return { ...appProps, cookies: {} };
};
const MyApp = ({ Component, emotionCache = clientSideEmotionCache, pageProps: { dehydratedState, ...pageProps }, customToken, cookies, }: AppPropsWithLayout & MuiProps & CustomTokenProps & CookieProps) => {
    const { replace, pathname } = useRouter();
    const [queryClient] = useState(() => new QueryClient());
    const getLayout = Component.getLayout ?? ((page) => page);
    const { theme: userTheme, darkModeActive } = useDarkMode();
    const theme = useMemo(() => createTheme(getDesignTokens(userTheme)), [userTheme]);
    useEffect(() => {
        const registerSW = async () => {
            try {
                const registration = await navigator.serviceWorker.getRegistration();
                if (!registration) {
                    await navigator.serviceWorker
                        .register(`/firebase-messaging-sw.js`)
                        .then((serviceWorkerRegistration) => {
                        const options = {
                            userVisibleOnly: true,
                            applicationServerKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
                        };
                        serviceWorkerRegistration.pushManager.subscribe(options).then((pushSubscription) => {
                            console.log(pushSubscription);
                        }, (error) => {
                            console.error(error);
                        });
                    });
                }
            }
            catch (registrationError) {
                console.error('SW registration failed: ', registrationError);
            }
        };
        if ('serviceWorker' in navigator)
            registerSW();
    }, []);
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const searchParamsKeyList = [...searchParams.keys()];
        const toBeDeletedSearchParamsList = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'fbclid',
        ];
        const checkHasKey = (key: string) => toBeDeletedSearchParamsList.includes(key);
        const isToBeDeletedSearchParams = searchParamsKeyList.some(checkHasKey);
        if (isToBeDeletedSearchParams) {
            replace(`${window.location.origin}${window.location.pathname}`);
            return;
        }
    }, [replace]);
    useIOSAPPInitSetting(darkModeActive);
    return (<FeatureFlagProvider>
      <CookieProvider cookies={cookies}>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"/>
          </Head>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider action={(snackbarId) => (<IconButton onClick={() => closeSnackbar(snackbarId)} color="inherit">
                  <CloseIcon />
                </IconButton>)} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }} autoHideDuration={3000} preventDuplicate={true}>
              <AuthenticationProviderStack customToken={customToken}>
                <HotkeyProvider>
                  <ScoreboardProvider>
                    <AppBarMenuListProvider>
                      <ThemeProvider theme={theme}>
                        <VideoUploadStatusProvider>
                          <VideoFilterAndSortProvider>
                            {globalStyle}
                            <CssBaseline />
                            <ReactQueryDevtools initialIsOpen={false}/>
                            <HydrationBoundary state={dehydratedState}>
                              <GoogleAdsScript />
                              <GoogleTagmanagerScript />
                              <GoogleMapScript />
                              {getLayout(<ErrorBoundary fallbackRender={() => <ErrorRefresh />} key={pathname}>
                                  <Component {...pageProps}/>
                                </ErrorBoundary>)}
                            </HydrationBoundary>
                          </VideoFilterAndSortProvider>
                        </VideoUploadStatusProvider>
                      </ThemeProvider>
                    </AppBarMenuListProvider>
                  </ScoreboardProvider>
                </HotkeyProvider>
              </AuthenticationProviderStack>
            </SnackbarProvider>
          </QueryClientProvider>
        </CacheProvider>
      </CookieProvider>
    </FeatureFlagProvider>);
};
const __Next_Translate__Page__192895d49b1__ = ParentApp;

    export default __appWithI18n(__Next_Translate__Page__192895d49b1__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  