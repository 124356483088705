import type { AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import axios from 'axios';

import generateTraceParent from 'utils/generateTraceParent';
import getGaClientId from 'utils/getGaClientId';

type ServerType = 'live' | 'studio' | 'studio-public' | 'studio-ai';
type VersionType = 'v1' | 'v2';

const TRACEPARENT = 'traceparent';

const getServerURL = (serverType: ServerType, version: VersionType) => {
  switch (serverType) {
    case 'studio':
      return `${process.env.NEXT_PUBLIC_CAMERAFI_API_GATEWAY_URL}/studio/api/${version}`;
    case 'studio-public':
      return `${process.env.NEXT_PUBLIC_CAMERAFI_API_GATEWAY_URL}/studio/public-api/${version}`;
    case 'live':
      return `${process.env.NEXT_PUBLIC_MAIN_SERVER_URL}/api/${version}`;
    case 'studio-ai':
      return `${process.env.NEXT_PUBLIC_CAMERAFI_STUDIO_API_URL}/studio/api/${version}`;
  }
};

const setupAxiosInstance = ({
  serverType,
  version,
  Authorization,
  token,
  channelUid,
}: {
  serverType: ServerType;
  version: VersionType;
  Authorization?: string;
  token?: string;
  channelUid?: string;
}) => {
  const headers: RawAxiosResponseHeaders | AxiosResponseHeaders = {
    'Content-Type': 'application/json',
    'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    Accept: 'application/json',
    Authorization,
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (channelUid) {
    headers['channelUid'] = channelUid;
  }

  const instance = axios.create({
    baseURL: getServerURL(serverType, version),
    headers,
  });

  instance.interceptors.response.use(
    (response) => response.data,
    (error) => Promise.reject(error.response?.data.message ?? error.message),
  );

  instance.interceptors.request.use(
    (config) => {
      config.headers[TRACEPARENT] = generateTraceParent();

      if (serverType !== 'live') {
        try {
          const clientId = getGaClientId();

          config.headers['X-Ga-Client-Id'] = clientId;
          // eslint-disable-next-line no-empty
        } catch {}
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return instance;
};

const publicAxios = ({
  serverType,
  version,
  Authorization,
}: {
  serverType: ServerType;
  version: VersionType;
  Authorization?: string;
}) => {
  const instance = setupAxiosInstance({ serverType, version, Authorization });

  return instance;
};

const authAxios = ({
  serverType,
  token,
  channelUid,
  version,
}: {
  serverType: ServerType;
  token: string;
  channelUid?: string;
  version: VersionType;
}) => {
  const instance = setupAxiosInstance({
    serverType,
    version,
    token,
    channelUid,
  });

  return instance;
};

const authAxiosWithoutChannelUid = ({
  serverType,
  token,
  version,
}: {
  serverType: ServerType;
  token: string;
  version: VersionType;
}) => {
  const instance = setupAxiosInstance({ serverType, version, token });

  return instance;
};

const studioPublicAxiosV1 = publicAxios({
  serverType: 'studio',
  version: 'v1',
});
const studioPublicAxiosV2 = publicAxios({
  serverType: 'studio',
  version: 'v2',
});
const studioPublicApiAxiosV1 = publicAxios({
  serverType: 'studio-public',
  version: 'v1',
});

const hostAxios = axios.create({
  baseURL: '/',
});

hostAxios.interceptors.request.use((config) => ({ ...config }));

hostAxios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response?.data.message ?? error.message),
);

const vimeoAxios = axios.create({
  baseURL: 'https://api.vimeo.com',
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_VIMEO_ACCESS_TOKEN}`,
  },
});

vimeoAxios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response?.data.message ?? error.message),
);

const studioPublicAxiosV1withAPIKey = publicAxios({
  serverType: 'studio-public',
  version: 'v1',
  Authorization: `Bearer ${process.env.NEXT_PUBLIC_CAMERAFI_STUDIO_API_KEY}`,
});

export {
  authAxios,
  authAxiosWithoutChannelUid,
  hostAxios,
  studioPublicAxiosV1,
  studioPublicAxiosV2,
  studioPublicApiAxiosV1,
  studioPublicAxiosV1withAPIKey,
  vimeoAxios,
};
