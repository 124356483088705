import { memo } from 'react';

import Script from 'next/script';

import { useTryLoginEventForGTM } from 'hooks/forGTM/useTryLoginEventForGTM';
import useActiveAuth from 'hooks/useActiveAuth';

const GoogleTagmanagerScript = () => {
  const { isProfileLoadProcessComplete } = useActiveAuth();

  useTryLoginEventForGTM();

  return isProfileLoadProcessComplete ? (
    <Script id="google-tagmanager" async>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_STUDIO_GOOGLE_TAGMANAGER_ID}');`}
    </Script>
  ) : null;
};

export default memo(GoogleTagmanagerScript);
