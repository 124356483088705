import { useCallback } from 'react';

import useAuth from 'hooks/useAuth';

import { CHANNEL_CHANGE_EVENT } from 'types/dataLayer';

import { addEventToDataLayer } from 'utils/forGTM/dataLayerHandlerForGTM';

const useChangeChannelEventForGTM = () => {
  const { user, isSubscribedUser } = useAuth();

  const changeOtherChannelEventForGTM = useCallback(
    ({
      managingChannel_id,
      managingChannel_isSubscriber,
      managingChannel_trialing,
    }: {
      managingChannel_id: string;
      managingChannel_isSubscriber: boolean;
      managingChannel_trialing: boolean;
    }) => {
      addEventToDataLayer({
        event: CHANNEL_CHANGE_EVENT,
        managingChannel_id,
        managingChannel_isSubscriber,
        managingChannel_trialing,
        isMyAccount: false,
      });
    },
    [],
  );

  const changeMyChannelEventForGTM = useCallback(() => {
    addEventToDataLayer({
      event: CHANNEL_CHANGE_EVENT,
      managingChannel_id: user?.id,
      managingChannel_isSubscriber: isSubscribedUser,
      managingChannel_trialing: user?.membershipStatus === 'trialing',
      isMyAccount: true,
    });
  }, [isSubscribedUser, user]);

  return { changeMyChannelEventForGTM, changeOtherChannelEventForGTM };
};

export default useChangeChannelEventForGTM;
