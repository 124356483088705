import {
  type RemoteConfig,
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from 'firebase/remote-config';

let remoteConfig: RemoteConfig | null = null;

const initGlobalRemoteConfig = async () => {
  remoteConfig = getRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 60000;
  await fetchAndActivate(remoteConfig);
};

export const getRemoteConfigValueByKey = async (key: string) => {
  if (remoteConfig === null) {
    await initGlobalRemoteConfig();
  }
  if (!remoteConfig) {
    throw new Error('remoteConfig is null');
  }
  return getValue(remoteConfig, key);
};
